import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  hotelDates: string;
} = {
  hotelDates: '',
};

export const searchSlice = createSlice({
  name: 'hotelDates',
  initialState,
  reducers: {
    updateHotelDates: (state, action) => {
      state.hotelDates = action.payload;
    },
  },
});

export const { updateHotelDates } = searchSlice.actions;

export default searchSlice.reducer;
