const translationsEn = {
    "status": "success",
    "data": {
        "lang": "en",
        "app": "hotel",
        "translation": {
            "crud": {
                "validationFailed" : "Validation failed",
                "operationError" : "Error occurred",
                "emptyList" : "No Data Found",
                "failToLoad" : "Error occurred on loading",
                "modelNotFound" : "Not Found",
            },
            "contactUs": {
                "email": "Email",
                "phone": "Phone",
                "fullName": "Full Name",
                "writeUsMessage": "Write us a message",
                "send": "Send",
                "phoneRequired": "Please enter a phone number",
                "emailRequired": "Please enter an email address",
                "fullNameRequired": "Please enter a full name",
                "messageRequired": "Please enter a message",
                "aboutUs": "About Us"
            },
            "cart": {
                "addToCart": "Add to Cart",
            },
            "customerSupport": {
                "customerService": "Customer Service",
                "sendMessageAndGetNotified": "Send us a message and the auctioneer will get back to you as soon as possible",
                "callUs": "Call us: ",
            },
            "roomInfo": {
                "info": "Room Description",
                "viewAllThePhotos": "View all the photos",
                "roomSize": "Room Size (sqm)",
                "peopleInTheRoom": "People in the Room",
                "doubleBed": "Double Bed"
            },
            "room": {
                "roomSize": "Room Size",
                "bedType": "Bed Type",
                "breakfastIncluded": "Breakfast Included",
                "noAvailabilityOnSelectedDates": "No availability for this room on the selected dates"
            },
            "promotion": {
                "modelNotFound": "Page not found",
                "failToLoad": "Failed to load the page!"
            },
            "searchDatePicker": {
                "search": "Search",
                "rooms": "Rooms",
                "composition": "Composition",
                "selectDate": "Select Date",
                "select_package_dates": "Select Package Dates",
                "chooseComposition": "Choose Composition",
                "numberOfGuests": "Number of Guests",
                "checkOut": "Check Out",
                "checkIn": "Check In",
                "whatVacation": "What kind of vacation",
                "youWant": "do you want",
                "vacationFriendsWith": "Vacation with friends",
                "familyVacation": "Family Vacation",
                "romanticVacation": "Romantic Vacation",
                "notAvailable": "Not Available",
                "roomAvailableDuringTheWeek": "Room availability during the week",
                "followingDates": "Following Dates",
                "vacation": "Vacation",
                "abroad": "Abroad",
                "inIsrael": "In the country",
                "destination": "All destinations",
                "chooseDestination": "Choose destinations",
                "children": "Children",
                "adults": "Adults",
                "findDreamVacation": "Find your perfect vacation with a click.",
                "searchResTitle": "Choose the right package for you",

                "dynamicFlight": "Dynamic Flight",
                "bundle": "Bundled Flight",
            },
            "specialDeal": {
                "closeTheDeal": "Close the deal ›",
                "endsIn": "Ends in",
                "specialDealForTheWeekend": "Special deal for the weekend",
                "from": "From date",
                "until": "Until date"
            },
            "footer": {
                "backToTop": "Back to top",
                "allRightsReserved": "© All rights reserved. Do not copy content from the site without permission.",
                "developBy": "Developed by SKY 360 Tech.",
                "thePurchaseOnTheWebsiteIsSecure": "The purchase on the site is secure.",
                "talkToUs": "How to talk to us?",
                "address": "Address",
                "phoneAndWhatsapp": "Phone or WhatsApp",
                "phone": "Phone",
                "whatsapp": "Whatsapp",
            },
            "packages": {
                "from": "From date",
                "until": "Until date",
                "breakfastIncluded": "Breakfast Included",
                "includesMassageAndSpa": "Includes massage and spa!",
                "lastRoom": "Last room!",
                "includesTransfers": "Includes transfers",
                "includesActivities": "Includes attractions",
                "nights": "Nights",
                "withoutBaggage": "Without luggage",
                "directFlight": "Direct flight",
                "oneStop":"One Stop",
                "numOfStops":"Stops",
                "flightDetails": "Flight details",
                "flightFrom": " from -> to",
                "flightBack": " from -> to",
                "hours": "Hours",
                "flightNumber": "Flight number",
                "airLine": "Airline",
                "suitcaseWeight": "weightkg Baggage Included",
                "trollyWeight": "weightkg Trolley included",
                "includedActivitiesTitle": "Attractions and tours in the package",
                "checkAllPictures": "View all the pictures",
                "affiliateWith": "In collaboration with",
                "addButtonDisabledTooltip": "Order is limited to room quantity - cannot add another room. To change a room, remove a room from the cart",
                "holidaySchedule": "Vacation schedule",
           "passengers":"Passengers"
            },
            "roomPricing": {
                "addPassenger": "Add Passenger",
                "pricePerPerson": "Price per person",
                "pricePerInfant": "Price per infant",
                "composition": "Composition",
                "adultsInTheRoom": "Adults",
                "children": "Children",
                "adults": "Adults",
                "single": "Single adult",
                "child": "Child",
                "selectInfantCount": "Select from the list",
                "infant": "Infant",
                "1infant": "One infant",
                "2infants": "2 infants",
                "infants": "Infants",
                "noInfants": "Without infants",
                "all_include": "All inclusive",
                "breakfast": "Breakfast",
                "activities": "Choose a tour",
                "half_board": "Half board",
                "half_board_kosher": "Kosher half board",
                "full_board": "Full board",
                "sleep_only": "Sleep only",
                "breakfast_kidush": "Friday morning and evening meal",
                "addToCard": "Add to order",
                "inPackageDates": "In package dates",
                "chooseRoom": "Choose composition and room",
                "nights": "Nights",
                "boardType": "Board basis",
                "filters": "Filters",
                "noAvailableRooms": "No rooms available for the selected dates",
                "noAvailableRoomsForDates": "Room not available for the selected dates",
                "addInfant": "Add infant"
            },
            "activitiesTranslations": {
                "addToOrder": "Add to order",
                "passenger": "To the passenger",
                "showMore": "Show more",
                "addAttractionsAndTrips": "Add attractions and trips"
            },
            "modal": {
                "viewAllImages": "View all images",
                "galleryTitle": "Photo gallery",
                "cancelationTerms": "Cancellation terms - ",
                "nameOfTheHotel": "Hotel details",
                "hotelInfo": "About the hotel",
                "hotelWebsite": "Link to the hotel website"
            },
            "shoppingCartTranslations": {
                "shoppingCartButtonTooltip": "Choose a composition and add to order",
                "totalPrice": "Total payment",
                "proceedToPayment": "Proceed to payment",
                "placeOrder": "Proceed to registration",
                "NoHotelWasChosenForTheVacation": "No hotel was chosen for the vacation",
                "betweenDates": "Between the dates",
                "packageInformation": "Package details",
                "rooms": "Room details",
                "transfers": "Transfers",
                "reg_transfers": "Transfers/shuttles to and from the hotel",
                "shuttle_transfers": "Shuttles from the border to the hotel and back",
                "flights": "Flights",
                "activities": "Attractions",
                "shoppingCart": "Shopping cart",
                "package": "Nights at the hotel",
                "nights": "Nights",
                "inHotel": "In the hotel",
                "apply": "Confirm",
                "promoCode": "Coupon code",
                "yourShoppingCart": "Your shopping cart",
                "isEmpty": "Still empty...",
                "myVacation": "My vacation",
                "showAll": "Show all »",
                "beforeDiscount": "Total before discount -",
                "agentCommission": "Agent commission -",
                "agentCommissionNet": "Agent commission (net payment) -",
                "discountFromCode": "Code discount -",
                "affiliateWith": "In collaboration with -",
                "tpId": "Order ID -",
                "chargeTypeFullCC": "Full payment - including commission",
                "chargeTypeNetCC": "Payment for net price",
                "chargeTypeCash": "Cash transaction",
                "agentCommissionPrec": "Agent commission percentage",
                "priceOff": "Discount",
                "percentageOff": "Discount percentage",
                "selectedItems": "Selected items"
            },
            "checkout": {
                "room": "Room",
                "personalInfo": "Vacationers' details",
                "paymentDetails": "Payment details",
                "orderCompletion": "Order completion",
                "userDetails": "Orderer's details",
                "name": "First Name",
                "lastName": "Family Name",
                "passport": "Passport",
                "inEnlgish": "",
                "birthdate": "Birthdate",
                "phone": "Phone",
                "email": "Email",
                "notesForTheOrder": "Notes for the order",
                "namesOfGuests": "Names of the passengers",
                "namesMustMatchPassport": "* All names must be typed in English exactly as they appear on the passport",
                "goToPayments": "Submit and proceed to payment",
                "saveOrder": "Save order",
                "agreement": "I confirm the website's terms, order terms, and the order cancellation terms",
                "men": "Male",
                "women": "Female",
                "pleaseInputYourPhone": "Please enter your phone number",
                "pleaseInputYourLastName": "Please enter your last name",
                "pleaseInputYourFirstName": "Please enter your first name",
                "pleaseInputYourEmail": "Please enter your email",
                "pleaseInputYourPassportNumber": "Please enter your passport number",
                "pleaseInputYourDateOfBirth": "Please enter your date of birth",
                "pleaseSelectGender": "Please select gender",
                "maxChildAge": "Child between the ages 2-",
                "maxInfantAge": "Infants must be under two years old at the time of the vacation",
                "pleaseAgreeWithT&C": "Please agree to the terms of use and website regulations",
                "viewTerms": "View regulations",
                "invalidEmail": "Invalid email",
                "orderCreateSuccess": "Your order has been saved successfully, you are being redirected to payment",
                "orderCreateFree": "Your order has been saved successfully!",
                "orderUpdateSuccess": "Your order has been updated successfully!",
                "mainRoomGuestTitle": "Room named after",
                "uploadPassportImage": "Upload passport photo",
                "addInfant": "Add infant",
                "updateInfant": "Update infant quantity",
                "howManyInfantsDoYouWantToAdd": "Choose the number of infants for this room",
                "add": "Add",
                "setInfantCountForRoom": "Update infant quantity",
                "setInfantCountForRoomSuccess": "The number of infants has been updated successfully",
                "infantsAddedToTheRoom": "Infants added to the room",
                "selectNumberOfInfants": "Choose number of infants",
                "pricePerInfant": "Price per infant",
                "infantsRemoved": "Infants were removed from this room",
                "requiredUploadPassportImage": "Uploading passport photo is mandatory",
                "infantError4403": "Cannot change the number of infants - please contact the service center",
                "docketId": "* Docket ID",
                "agencyId": "* Agency ID"
            },
            "manageOrder": {
                "moveToPayment": "Add payment method",
                "backToManage": "Return to order management",
                "paymentTitle": "Add payment method",
                "manageTitle": "Update vacationers' details",
                "modelNotFound": "Order not found",
                "orderId": "Order management"
            },
            "completionForm": {
                "paymentFinish": {
                    "title": "The order was made successfully - payment completed",
                    "info": "An email with order details was sent to your address"
                },
                "waitingToApprove": {
                    "title": "Your order has been sent for approval",
                    "info1": "Order details were saved in the system and sent for approval.",
                    "info2": "Payment will only be made after order approval.",
                    "info3": "An email with order confirmation and payment will be sent after order approval."
                },
                "paymentFail": {
                    "title": "Payment failed",
                    "info1": "Payment failed :(",
                    "infoFail": "After several attempts, the charge failed - please try again",
                    "infoWhatsapp": "If an error occurred and you cannot pay, please contact via WhatsApp at: "
                },
                "orderNum": "Order number",
                "thanksForChoose": "Thank you for choosing",
                "free": {
                    "title": "Registration was successful",
                    "info": "An email with a summary of the order was sent to you"
                },
                "pay_later": {
                    "title": "Registration was successful",
                    "info": "An email with order confirmation was sent to you"
                }
            },
            "paymentForm": {
                "creditNotSaveInBd": "Credit card details are not saved on the site - you are making a secure payment",
                "title": "Enter payment method (Visa & Mastercard ONLY)",
                "splitBtn": "Click to split credit cards",
                "splitTitle": "Split credit cards",
                "splitDesc": "Choose the number of credit cards for splitting and click confirm",
                "noSplit": "No split",
                "noPayments": "No payments",
                "payments": "Payments",
                "paymentsNum": "Number of payments",
                "withDebit": "With credit",
                "splitBy": "Credit cards",
                "toPay": "To pay",
                "splitApprove": "Confirm",
                "currencyRate": "Charging is done in shekels - according to the tourism rate",
                "splitCurrentCardNum": "Credit split",
                "splitCurrentCardNumFrom": "Out of",
                "errorMessageToRetry": "Attempt No. {retryNum} - Payment failed from the credit card company. Please enter again card details or another card. Website accepts VISA and MASTERCARD cards only.",
            },
            "hotelDetails": {
                "updateDates": "Update dates",
                "roomTypesToChooseFrom": "Room types to choose from"
            },
            "general": {
                "noResults": "No results",
                "nights": "Nights",
                "includesActivities": "{NUMBER} gift attractions!",
                "includesActivity": "Includes a gift attraction!",
                "adults": "Adults",
                "all_include": "All inclusive",
                "breakfast": "Breakfast",
                "half_board": "Half board",
                "half_board_kosher": "Kosher half board",
                "full_board": "Full board",
                "sleep_only": "Accommodation only",
                "breakfast_kidush": "Friday breakfast and dinner",
                "addToCard": "Add to order",
                "chooseRoom": "Choose composition and room",
                "boardType": "Accommodation basis",
                "filters": "Filters",
                "rating": "Hotel rating",
                "children": "Children",
                "toDate": "Until",
                "transfers": "Transfers",
                "from": "Starting from",
                "perPerson": "Per person",
                "include": "Includes",
                "noIncludeText": "Vacation packages",
                "flights": "Flights",
                "vacationAt": "Vacation in {DEST}",
                "fieldRequired": "Mandatory field"
            },
            "subsidizedForm": {
                "title": "Add subsidy",
                "employee_id": "Employee number",
                "person_id": "ID number (9 digits)",
                "validate": "Check employee code",
                "alreadyApplied": "Subsidy already applied",
                "addOneMore": "Add another subsidy",
                "addOneMoreId": "Add another employee",
                "fieldRequired": "Mandatory field",
                "priceReducedBy": "Price reduced by",
                "forEmployee": "For employee",
                "employeeIs": "Successfully identified",
                "error4401": "Failed to identify, try again",
                "error4402": "Code identified, but already in use",
                "guestIdentifiedId": "Identified by ID",
                "guestNotIdentified": "You must identify before registering",
                "clickToIdentify": "Click to identify"
            },
            "uploadImage": {
                "uploadPassportImage": "Upload passport photo",
                "somethingWentWrong": "Something went wrong"
            }
        }
    }
};

export default translationsEn;