
const appConfigGuestRoom = {

        siteConfig: {
            defaultLang: 'he',
            direction: "rtl",
            languages: ['he', 'en'],
            name: 'My Guest Room',
            is_multi_hotel: 1,
            domain: 'gr.familybreak.co.il',
            id: 1,
        },
        theme: {
            primary: '#d2c186',
            secondary: '#6bd7e8',
            tertiary: '#c2c2c2',
            background: '#fff',
            button: 'btn-style-2',
            fontFamily: 'system-ui',
            title: 'title-style-2',
        },
        layouts: {
            default: { topModules: ['Header'], bottomModules: ['Footer'] },
            noFooter: { topModules: [], bottomModules: ['Footer'] },
            empty: { topModules: [], bottomModules: [] },
        },
        pages: [
            {
                path: '/',
                modules: [
                    'ImgCarousel',
                    'SearchDatePickerMainPage',
                    'DatesSuggestion',
                    'PackageListView',
                    'SpecialDeal',
                    //'ActivitiesSuggestion',
                    //'ActivitiesListView',
                    'Description',
                    'BannerLarge',
                    'ContactUs',
                ],
                name: 'Main Page',
                type: 'indexPage',
                layout: 'default',
                seo: { title: '', desc: '' },
            },
            {
                path: '/room/:id',
                modules: [
                    'ImgCarousel',
                    'SearchDatePicker',
                    'RoomInfo',
                    'OrderNow',
                    'Activities',
                    'RoomGeneralDetails',
                ],
                name: 'Room Info',
                type: 'roomPage',
                layout: 'default',
                seo: { title: '', desc: '' },
            },
            {
                path: '/packages',
                modules: ['ImgCarousel', 'SearchDatePickerSearchPage'],
                name: 'Search',
                type: 'searchResultPage',
                layout: 'default',
                seo: { title: '', desc: '' },
            },
            {
                path: '/package/:id',
                modules: ['PackageDetails', 'RoomPricing', 'Activities'],
                name: 'Package Info',
                type: 'packagePage',
                layout: 'default',
                seo: {
                    title: '',
                    desc: '',
                },
            },
        ],
        modulesConfigs: {
            Header: {
                links: [
                    { text: 'עמוד הבית', url: '/' },
                    { text: 'על הצימר', url: '/bandb' },
                    { text: 'החדרים', url: '/rooms' },
                    { text: 'אטרקציות', url: '/attractions' },
                    { text: 'צור קשר', url: '/contact' },
                ],
                theme: { tertiary: '#ccc', background: '#fff' },

                set: 'HeaderSet1',
                logo: "/assets/images/logo-large.png",
                widgetSocial: {
                    isActive: false,
                    socials: [
                        {
                            key: 'instagram',
                            value: 'https://www.instagram.com/familybreak.co.il/',
                        },
                        {
                            key: 'facebook',
                            value: 'https://www.instagram.com/familybreak.co.il/',
                        },
                        {
                            key: 'whatsapp',
                            value:
                                'whatsapp://send?text=ברוכים הבאים למענה אנושי של פמילי ברייק , רישמו לנו ואנו נענה בהקדם!&phone=+972777729055',
                        },
                    ],
                },
                module: 'Header',
            },
            Footer: {
                columns: [
                    {
                        header: 'צור קשר',
                        links: [
                            {
                                text: 'כתבו לנו',
                                url: '/',
                            },
                            {
                                text: 'איפה אנחנו נמצאים?',
                                url: '/',
                            },
                            {
                                text: 'שירות לקוחות',
                                url: '/',
                            },
                            {
                                text: 'שירות לקוחות',
                                url: '/',
                            },
                        ],
                    },
                    {
                        header: 'אטרקציות',
                        links: [
                            {
                                text: 'ספא',
                                url: '/',
                            },
                            {
                                text: 'מסעדות',
                                url: '/',
                            },
                            {
                                text: 'ספא משפחתי',
                                url: '/',
                            },
                            {
                                text: 'לראות את הצפון',
                                url: '/',
                            },
                            {
                                text: 'טיול קניות',
                                url: '/',
                            },
                            {
                                text: 'טיול טבע',
                                url: '/',
                            },
                            {
                                text: 'טיול למדבר',
                                url: '/',
                            },
                        ],
                    },
                    {
                        header: 'החדרים',
                        links: [
                            {
                                text: 'חדר סטנדטנ',
                                url: '/',
                            },
                            {
                                text: 'חדר אקזקיוטיב',
                                url: '/',
                            },
                            {
                                text: 'חדר משפחה',
                                url: '/',
                            },
                            {
                                text: 'חדר סוויטה',
                                url: '/',
                            },
                        ],
                    },
                ],
                set: 'FooterSet1',
                module: 'Footer',
                widgetScrollToTop: {
                    isActive: true,
                },
                "logo": "/assets/images/logo-large.png",
            },
            SearchDatePickerMainPage: {
                module: 'SearchDatePicker',
                theme: {
                    title: 'title-style-1',
                },
                set: 'SearchDatePickerSmallPackage',
                backgroundImg:
                    'https://www.familybreak.co.il/themes/familybreak2/images/slides/slide-df.jpg',
                widgetDatePickerCarousel: {
                    isActive: false,
                },
                widgetVacationTypes: {
                    isActive: true,
                },
            },
            BannerLarge: {
                img: 'https://www.africanbusinessreview.co.za/wp-content/uploads/2021/06/take-a-vacation.jpeg',
                textLine1: 'החוויה הכי טובה בצפון',
                textLine2: 'בואו לחוות חופשה אחרת.',
                module: 'BannerLarge',
            },
            ImgCarousel: {
                carousel: [
                    {
                        img: 'https://www.africanbusinessreview.co.za/wp-content/uploads/2021/06/take-a-vacation.jpeg',
                        text: 'צימר רמת הגולן המקום המושלם.',
                    },
                    // {
                    //     img: 'https://cdn2.hubspot.net/hubfs/439788/Blog/Featured%20Images/Best%20Hotel%20Website%20Designs.jpg',
                    //     text: 'צימר רמת הגולן המקום המושלם.',
                    // },
                ],
                theme: { title: 'title-style-1' },
                module: 'ImgCarousel',
            },
            DatesSuggestion: {
                buttonText: 'לעוד דילים טובים ›',
                url: '#',
                title: 'תאריכי חופשה',
                titleLineTwo: 'פופולאריים',
                theme: { title: 'title-style-2' },
                module: 'DatesSuggestion',
            },
            Description: {
                img: 'https://www.africanbusinessreview.co.za/wp-content/uploads/2021/06/take-a-vacation.jpeg',
                description: 'המקום המושלם לחופש, שקט והנאה למשפחות.',
                theme: { title: 'title-style-2' },
                module: 'Description',
            },
            ContactUs: {
                "set":"ContactUsSet1",
                img: 'https://www.africanbusinessreview.co.za/wp-content/uploads/2021/06/take-a-vacation.jpeg',
                email: {
                    email: 'my@gmail.com',
                    text: 'שעות פעילות',
                    additionalText: '08:00-15:00',
                },
                phone: {
                    phone: '03-5255555',
                    text: 'או כתבו לנו בטופס',
                    additionalText: 'בצד.',
                },
                theme: {
                    button: 'btn-style-1',
                    title: 'title-style-4',
                },
                module: 'ContactUs',
            },
            SearchDatePickerSearchPage: {
                module: 'SearchDatePicker',
                theme: {
                    title: 'title-style-1',
                },
                set: 'SearchDatePickerSmallPackage',
                widgetDatePickerCarousel: {
                    isActive: true,
                },
                widgetVacationTypes: {
                    isActive: false,
                },
            },
            RoomInfo: {
                theme: {
                    title: 'title-style-1',
                },
                set: 'RoomInfoSet1',
                widgetRoomDetails: {
                    isActive: true,
                },
                widgetRoomFacilities: {
                    isActive: true,
                },
                widgetRoomPictures: {
                    isActive: true,
                },
                facilities: [
                    'TV',
                    'Telephone',
                    'Card',
                    'Bed',
                    'Radio',
                    'BreakFast',
                    'Porter',
                    'FiveStar',
                    'Juice',
                    'HouseKeeping',
                    'DoNotDisturb',
                    'Hostess',
                ],
                module: 'RoomInfo',
            },
            OrderNow: { theme: { background: '#42595d' }, module: 'OrderNow' },
            ActivitiesSuggestion: {
                buttonText: 'לכל האטרקציות ›',
                url: '#',
                title: 'אטרקציות וחוויות',
                titleLineTwo: 'בקרבת מקום',
                theme: { title: 'title-style-3' },
                module: 'ActivitiesSuggestion',
            },
            Activities: {
                theme: {
                    secondary: '#d2c186',
                },
                set: 'ActivitiesSet2',
                module: 'Activities',
            },
            SearchDatePicker: {
                module: 'SearchDatePicker',
                theme: {
                    title: 'title-style-1',
                },
                set: 'SearchDatePickerSmallPackage',
                widgetDatePickerCarousel: {
                    isActive: false,
                },
                widgetVacationTypes: {
                    isActive: false,
                },
                destinations: {
                    local: {
                        code: 'IL',
                        cities: ['Tel-aviv', 'Jerusalem '],
                    },
                    global: [
                        {
                            code: 'IL',
                            cities: ['Tel-aviv', 'Jerusalem '],
                        },
                        {
                            code: 'UK',
                            cities: ['London', 'Manchester'],
                        },
                    ],
                },
            },
            "PackageListView": {
                "set": "PackageListViewSet2",
                "module": "PackageListView",
                "backgroundType":"type1",
                "defaultFilters":{
                    "staticFilter": "type=indexList",
                    "limit":3,
                    "dates": {
                        "from": 1,
                        "range": 14
                    }
                },
                "widgetFilterMenu": {
                    "isActive": false,
                },
                "widgetMorePackages": {
                    "isActive": false
                },
                "widgetPackageBoxHeader": {
                    "isActive": true,
                    "title":"חבילות הנופש הנצפות ביותר"
                }
            },
            ActivitiesListView: {
                set: 'ActivitiesListViewSet2',
                theme: {
                    button: 'btn-style-5',
                },
                widgetFilterMenu: {
                    isActive: false,
                    listFilters: [
                        {
                            name: 'סוכות',
                            filters: {
                                dates: '04/12/2022 - 02/01/2023',
                                limit: '4',
                                destination: '',
                            },
                        },
                        {
                            name: 'ראש השנה',
                        },
                    ],
                },
                widgetMorePackages: {
                    isActive: false,
                },
            },
            PackageDetails: {
                theme: {
                    button: 'btn-style-1',
                },
                widgetFlights: {
                    isActive: true,
                    widgetSet: 'FlightsSet1',
                },
                widgetHotelInfo: {
                    isActive: true,
                    widgetSet: 'HotelInfoSet1',
                },
                widgetActivities: {
                    isActive: true,
                    widgetSet: 'ActivitiesSet1',
                },
            },
        },
};

export default appConfigGuestRoom;
