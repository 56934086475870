import { createSlice } from '@reduxjs/toolkit';
import { configInterface } from '../interfaces';

const initialState: stateInterface = {
  config: {} as configInterface,
  translations: {},
  agentInfo: {},
  currentLang: 'he'
};


interface stateInterface {
  config:configInterface,
  translations:any,
  agentInfo:any,
  currentLang:any,
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      state.config = action.payload;
    },
    updateSitePhone: (state, action) => {
      debugger
      const phone = action.payload;
      if (phone) {

        state.config.siteConfig.phone.value = phone;
        state.config.siteConfig.whatsapp.phone = phone;
        state.config.siteConfig.companyInfo.address = "";

        let cleaned = phone.replace(/\D/g, '');
        // Add the country code prefix
        let whatsappPhone = '+972' + cleaned.slice(1);

        if (state.config?.modulesConfigs?.Header?.widgetSocial?.socials) {
          // @ts-ignore
          state.config.modulesConfigs.Header.widgetSocial.socials =
              [{
                "key": "instagram",
                "value": "https://www.instagram.com/familybreak.co.il/",
              }, {
                "key": "facebook",
                "value": "https://www.facebook.com/familybreak.co.il/"
              }, {
                "key": "whatsapp",
                "value": "whatsapp://send?text=ברוכים הבאים איך אפשר לעזור?&phone=" + whatsappPhone,
              }
              ];
        }
      }
    },
    updateLang: (state, action) => {
      state.currentLang = action.payload;
    },
    updateTranslations: (state, action) => {
      state.translations = action.payload;
    },
    updateAgentInfo: (state, action) => {
      state.agentInfo = action.payload;
    },
    updateModuleTheme: (state: any, action) => {
      const { moduleName, theme } = action.payload;
      state.config.modulesConfigs[moduleName].theme = theme;
    },
    updateSiteTheme: (state, action) => {
      const { theme } = action.payload;
      state.config.theme = theme;
    },
    updateLayout: (state, action) => {
      const { layouts } = action.payload;
      state.config.layouts = layouts;
    },
  },
});

export const {
  updateConfig,
  updateSitePhone,
  updateTranslations,
  updateAgentInfo,
  updateModuleTheme,
  updateSiteTheme,
  updateLayout,
  updateLang
} = configSlice.actions;


export default configSlice.reducer;
