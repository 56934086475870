import React, {useEffect, useMemo, useCallback, useState, lazy} from 'react';
import {Route, Routes, useLocation, useSearchParams} from 'react-router-dom';
import Layout from './components/Layout/Layout';
import _, { isArray, isEmpty } from 'lodash';
import { Spin } from 'antd';
import { ConfigProvider } from 'antd';
import useCssVariables from './hooks/useCssVariables';
import { updateConfig } from './store/config';
import { useDispatch, useSelector } from 'react-redux';
import he from 'antd/es/locale/he_IL';
import { RootState } from './store/store';
import apiClient from '@skygroup/shared/API/apiClient';
import appConfigFamily from './appConfigFamilybreak';
import appConfigSky360 from './appConfigSky360';
import appConfigHotel360 from './appConfigHotel360';
import appConfigIssta from './appConfigIssta';
import appConfigSap from './appConfigSap';
import appConfigIpc from './appConfigIpc';
import appConfigUniversal from './appConfigUniversal';
import appConfigStudents from './appConfigSkystudents';
import appConfigSoho from './appConfigSoho';
import appConfigGuestRoom from './appConfigGuestRoom';
import ShoppingCart from './modules/ShoppingCart/ShoppingCart';
import { updateSearchInitialState } from './modules/SearchDatePicker/searchDatePickerSlice';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './assets/fonts/Heebo/heebo.css';
import endpoints from './utils/endpoints';
import SpinLoader from '@skygroup/shared/components/spin-loader/SpinLoader';
import useSiteLang from './hooks/useSiteLang';
import { useLoadAgentUser } from './hooks/useLoadAgentUser';
// import ShoppingCartV2 from './modules/ShoppingCart/ShoppingCartV2';
import appConfigMona from "./appConfigMona";
import appConfigSkyGroups from "./appConfigSkyGroups";
const queryClient = new QueryClient();

function App() {
  const dispatch = useDispatch();

  const { isAgentLoading } = useLoadAgentUser();

  const { config } = useSelector((data: RootState) => data.config);
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const search = useSelector((state: RootState) => state.search);
  const location = useLocation();

  const getConfig = useCallback(async () => {

    const response = await apiClient.get(`${endpoints.APP.config}?siteId=1`);
    // const appConfig = response.data?.data;
    const host = process.env.REACT_APP_HOST ?? window.location.host;

    let appConfig: any = false;

    if (host == 'stg-b2c.familybreak.co.il' || host == 'www.familybreak.co.il') {
      appConfig = appConfigFamily;
    } else if (host == 'groups.sky360.co.il') {
      appConfig = appConfigSkyGroups;
    } else if (host == 'issta.sky360.co.il') {
      appConfig = appConfigIssta;
    } else if (host == 'sap.sky360.co.il') {
      appConfig = appConfigSap;
    } else if (host == 'guestroom.sky360.co.il') {
      appConfig = appConfigGuestRoom;
    } else if (host == 'mona.sky360.co.il') {
      appConfig = appConfigMona;
    } else if (host == 'universal.sky360.co.il') {
      appConfig = appConfigUniversal;
    } else if (host == 'www.skystudents.co.il') {
      appConfig = appConfigStudents;
    } else if (host == 'soho.sky360.co.il') {
      appConfig = appConfigSoho;
    } else if (host == 'hotel.sky360.co.il') {
      appConfig = appConfigHotel360;
    } else if (host == 'stg-b2c.sky360.co.il') {
      appConfig = appConfigSky360;
    }

    if (!appConfig) {
      console.error("Failed to load config based on domain - " + host);
      return;
    }
    document.title = appConfig.siteConfig.name;
    dispatch(updateConfig(appConfig));

    const searchParams = new URLSearchParams(window.location.search);
    const fromValue = searchParams.get('from');

    // @ts-ignore
      dispatch(
        appConfig.siteConfig.defaultDate &&
          updateSearchInitialState({
            from: appConfig.siteConfig.defaultDate.from,
            range: appConfig.siteConfig.defaultDate.range,
            destination:appConfig.siteConfig.defaultDestination,
            destinationCity:appConfig.siteConfig.defaultDestinationCity,
            departure:appConfig.siteConfig.defaultDeparture,
            departureCity:appConfig.siteConfig.defaultDepartureCity,
          })
      );
    ConfigProvider.config({
      theme: {
        primaryColor: appConfig.theme.primary,
      },
    });
    setIsConfigLoaded(true);
  }, [dispatch]);

  useEffect(() => {
    getConfig();
  }, []);

  function insertAt(array: any, index: number, value: string) {
    return [..._.slice(array, 0, index), value, ..._.slice(array, index)];
  }

  function replaceAt(array: any[], index: number, value: any): any[] {
    let newArray = [...array];
    newArray[index] = value;
    return newArray;
  }

  useEffect(() => {
    const isEventOriginValid = (origin: string) =>
      origin === 'http://localhost:3000';

    const handleAddNewModule = (event: any) => {
      const currentPage = config.pages.find(
        (page) => page.name === 'Package Info'
      );
      const updatedModules = insertAt(
        currentPage?.modules,
        event.data.index,
        event.data.module
      );
      const updatedPages = config.pages.map((page) =>
        page.name === 'Package Info'
          ? { ...page, modules: updatedModules }
          : page
      );

      dispatch(updateConfig({ ...config, pages: updatedPages }));
    };

    const handleAddNewLayoutModule = (event: any) => {
      const currentPage = config.pages.find(
        (page) => page.name === 'Package Info'
      );

      const currentLayout = currentPage?.layout ?? '';

      const cloneLayouts = { ...config.layouts };
      const updatedModules = insertAt(
        cloneLayouts[currentLayout]?.[event.data.section],
        event.data.index,
        event.data.module
      );

      const currentLayout2 = {
        ...cloneLayouts[currentLayout],
        [event.data.section]: updatedModules,
      };
      const layouts = { ...config.layouts, [currentLayout]: currentLayout2 };
      dispatch(updateConfig({ ...config, layouts: layouts }));
    };

    const handleChangeLayout = (event: any) => {
      let index = 0;
      config.pages.forEach((page, i) => {
        if (page.name === event.data.updatedLayout.name) {
          index = i;
        }
      });

      const updatedPages = replaceAt(
        config.pages,
        index,
        event.data.updatedLayout
      );
      dispatch(updateConfig({ ...config, pages: updatedPages }));
    };

    const handleOtherEventTypes = (event: any) => {
      const updatedConfig = {
        ...config,
        modulesConfigs: { ...config.modulesConfigs, ...event.data },
      };
      dispatch(updateConfig(updatedConfig));
    };

    const handleAddNewLayoutConfig = (event: any) => {
      const updatedConfig = {
        ...config,
        layouts: event.data.newLayout.layouts,
      };
      dispatch(updateConfig(updatedConfig));
    };

    const eventHandler = (event: any) => {
      if (!isEventOriginValid(event.origin)) return;
      switch (event.data.type) {
        case 'AddNewModule':
          handleAddNewModule(event);
          break;
        case 'AddNewLayout':
          handleAddNewLayoutModule(event);
          break;
        case 'changeLayout':
          handleChangeLayout(event);
          break;
        case 'AddLayoutNewConfig':
          handleAddNewLayoutConfig(event);
          break;
        default:
          handleOtherEventTypes(event);
      }
    };
    window.addEventListener('message', eventHandler);
    return () => window.removeEventListener('message', eventHandler);
  }, [config]);

  const {isTranLoaded, direction} = useSiteLang({ siteConfig: config?.siteConfig});

  const generateCssVariables = useCssVariables();

  const topClass = config?.siteConfig?.style?.topClass
    ? config?.siteConfig?.style?.topClass
    : '';
  return (
    <SpinLoader isLoading={!(isConfigLoaded && isTranLoaded) || isAgentLoading}>
      <QueryClientProvider client={queryClient}>
        <div
          className={`App direction-${direction} ${topClass}`}
          id="App"
          dir={direction}
        >
          {generateCssVariables('body', config?.theme)}
          {!(
            location.pathname.split('/')[1] === 'checkout' ||
            location.pathname === '/checkout' ||
            location.pathname.split('/')[1] === 'order' ||
            location.pathname === '/order'
          ) && <ShoppingCart pageType="rest" />}

          <ConfigProvider direction={direction} locale={he}>
            <Routes>
              {config?.pages?.map((page, i) => {
                const pathArray: string[] = isArray(page.path)
                  ? page.path
                  : [page.path];
                return pathArray.map((path, index) => {
                  return (
                    <Route
                      key={index + path}
                      path={page.name === 'NotFound404' ? '*' : path}
                      element={
                        <Layout
                          pageComponents={page.modules}
                          layout={page.layout}
                        />
                      }
                    />
                  );
                });
              })}
            </Routes>
            {isEmpty(config) && <Spin className="center" size="large" />}
          </ConfigProvider>
        </div>
      </QueryClientProvider>
    </SpinLoader>
  );
}

export default App;
