import { Spin } from 'antd';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import importComponents from '../../utils/importComponents';
import { ModuleNames } from '../../interfaces';
import './layout.less';
import useCssVariables from '../../hooks/useCssVariables';
import classPriority from '../../utils/classPriority';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import EditComponent from '../../utils/EditComponent';
import EditLayoutComponent from '../../utils/EditLayoutComponent';

interface Props {
  pageComponents: string[];
  layout: string;
}

const Layout = ({ pageComponents, layout }: Props) => {
  const { modulesConfigs, layouts, theme } = useSelector(
    (data: RootState) => data.config.config
  );
  const [isEditModeOn, setIsEditModeOn] = React.useState<string | null>(null);

  const footerComponents = useMemo(() => {
    return importComponents(layouts[layout].bottomModules, modulesConfigs);
  }, [layouts, layout, modulesConfigs]);

  const components = useMemo(() => {
    return importComponents(pageComponents, modulesConfigs);
  }, [pageComponents, modulesConfigs]);

  const headerComponents = useMemo(() => {
    return importComponents(layouts[layout].topModules, modulesConfigs);
  }, [layouts, layout, modulesConfigs]);

  const [componentsState, setComponents] = useState<any>();

  useEffect(() => {
    setComponents(components);
  }, [components]);

  const mapComponents = (
    componentsArray: {
      Component: React.LazyExoticComponent<React.ComponentType<any>>;
      ComponentName: any;
    }[]
  ) => {
    return componentsArray?.map((component: any, i: number) => {
      const {
        Component,
        ComponentName,
      }: { Component: any; ComponentName: ModuleNames } = component;
      return (
        <EditComponent
          instanceName={ComponentName}
          key={i}
          id={ComponentName}
          index={String(i)}
          setComponents={setComponents}
          isEditModeOn={isEditModeOn}
          setIsEditModeOn={setIsEditModeOn}
        >
          <div className={ComponentName} key={i}>
            {modulesConfigs[ComponentName]?.theme &&
              generateCssVariables(
                `.${ComponentName}`,
                modulesConfigs[ComponentName].theme as any
              )}
            <Component
              config={modulesConfigs[ComponentName]}
              componentStyle={classPriority(
                theme,
                modulesConfigs[ComponentName]
              )}
            />
          </div>
        </EditComponent>
      );
    });
  };

  const mapComponentsLayout = (
    componentsArray: {
      Component: React.LazyExoticComponent<React.ComponentType<any>>;
      ComponentName: any;
    }[],
    name: string
  ) => {
    return componentsArray?.map((component: any, i: number) => {
      const {
        Component,
        ComponentName,
      }: { Component: any; ComponentName: ModuleNames } = component;

      return (
        <div className={ComponentName} style={{marginBottom: ComponentName === 'Header' ? 0 : 90}} key={i}>
          {ComponentName &&
            modulesConfigs[ComponentName]?.theme &&
            generateCssVariables(
              `.${ComponentName}`,
              modulesConfigs[ComponentName].theme as any
            )}
          <EditLayoutComponent
            instanceName={ComponentName}
            key={i}
            id={ComponentName}
            index={String(i)}
            isEditModeOn={isEditModeOn}
            setIsEditModeOn={setIsEditModeOn}
            layout={layout}
            name={name}
          >
            <Component
              config={modulesConfigs[ComponentName]}
              componentStyle={classPriority(
                theme,
                modulesConfigs[ComponentName]
              )}
            />
          </EditLayoutComponent>
        </div>
      );
    });
  };
  const generateCssVariables = useCssVariables();

  return (
    <div className="app-wrapper">
      <Suspense
        fallback={<Spin className="center lazy-loader-spinner" size="large" />}
      >
        {mapComponentsLayout(headerComponents, 'topModules')}
        {mapComponents(componentsState)}
        {mapComponentsLayout(footerComponents, 'bottomModules')}
      </Suspense>
    </div>
  );
};

export default Layout;
