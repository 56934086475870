import React from 'react';
import {
  FlightPrice,
  PaxCount,
} from '../../SearchDatePicker/SearchDatePickerInterface';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

interface ShoppingCartPassengersProps {
  pax: PaxCount;
  price: FlightPrice;
  selectedSuitcase: any;
  selectedTrolly: any;
  suitcasePrice: number;
  trollyPrice: number;
}

const ShoppingCartPassengers = ({
  pax,
  price,
  selectedSuitcase,
  selectedTrolly,
  suitcasePrice,
  trollyPrice,
}: ShoppingCartPassengersProps) => {
  const { shoppingCartTranslations: t, general } = useSelector(
    (data: RootState) => data.config.translations
  );

  return (
    <>
      <div
        style={{ paddingBottom: 10 }}
        className="rooms-activities passengers"
      >
        <b>{t.passengers}</b>

        {Array.from({ length: pax.adult }).map((_, index) => {
          const key = `adult-${index}`;
          let totalPrice = price.adult;

          const addOns = [
            {
              selected: selectedSuitcase[key],
              label: t.suitcase,
              price: suitcasePrice,
            },
            {
              selected: selectedTrolly[key],
              label: t.trolly,
              price: trollyPrice,
            },
          ];

          totalPrice += addOns.reduce(
            (sum, addOn) => (addOn.selected ? sum + addOn.price : sum),
            0
          );

          return (
            <div key={key}>
              <div className="product-line product-line-passenger">
                <span className="activity">
                  <div className="index">{index + 1}</div>
                  <span>
                    <div className="name">{general.adult}</div>
                    {addOns.map(
                      (addOn, addOnIndex) =>
                        addOn.selected && (
                          <div key={addOnIndex}>
                            {addOn.label}{' '}
                            <b>
                              {addOn.price}
                              {price.currency_sign}
                            </b>
                          </div>
                        )
                    )}
                  </span>
                </span>
                <div className="price-delete price-delete-flight">
                  <div>
                    <b>
                      {price.adult}
                      {price.currency_sign}
                    </b>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {Array.from({ length: pax.child }).map((_, index) => {
          const key = `child-${index}`;
          let totalPrice = price.child;

          const addOns = [
            {
              selected: selectedSuitcase[key],
              label: t.suitcase,
              price: suitcasePrice,
            },
            {
              selected: selectedTrolly[key],
              label: t.trolly,
              price: trollyPrice,
            },
          ];

          totalPrice += addOns.reduce(
            (sum, addOn) => (addOn.selected ? sum + addOn.price : sum),
            0
          );

          return (
            <div key={key} className="product-line product-line-passenger">
              <span className="activity">
                <div className="index">{index + 1}</div>
                <span>
                  <div className="name">{general.child}</div>

                  {addOns.map(
                    (addOn, addOnIndex) =>
                      addOn.selected && (
                        <div key={addOnIndex}>
                          {addOn.label}{' '}
                          <b>
                            {addOn.price}
                            {price.currency_sign}
                          </b>
                        </div>
                      )
                  )}
                </span>
              </span>
              <div className="price-delete price-delete-flight">
                <div>
                  <b>
                    {price.child}
                    {price.currency_sign}
                  </b>
                </div>
              </div>
            </div>
          );
        })}

        {Array.from({ length: pax.inf }).map((_, index) => {
          const key = `infant-${index}`;

          const addOns = [
            {
              selected: selectedSuitcase[key],
              label: t.suitcase,
              price: suitcasePrice,
            },
            {
              selected: selectedTrolly[key],
              label: t.trolly,
              price: trollyPrice,
            },
          ];

          return (
            <div key={key} className="product-line product-line-passenger">
              <span className="activity">
                <div className="index">{index + 1}</div>
                <span>
                  <div className="name">{general.infant}</div>
                  {addOns.map(
                    (addOn, addOnIndex) =>
                      addOn.selected && (
                        <div key={addOnIndex}>
                          {addOn.label}{' '}
                          <b>
                            {addOn.price}
                            {price.currency_sign}
                          </b>
                        </div>
                      )
                  )}
                </span>
              </span>
              <div className="price-delete price-delete-flight">
                <div>
                  <b>
                    {price.infant}
                    {price.currency_sign}
                  </b>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ShoppingCartPassengers;
