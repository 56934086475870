import React from 'react';
import { FaUmbrellaBeach } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import LogoSvg from '../../../assets/svgs/LogoSvg';
import { RootState } from '../../../store/store';

interface Props {
  shoppingCartOpen: boolean;
  checkout: boolean;
  setShoppingCartOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShoppingCartHeader = ({
  shoppingCartOpen,
  checkout,
  setShoppingCartOpen,
}: Props) => {
  const { shoppingCartTranslations:t } = useSelector(
    (data: RootState) => data.config.translations
  );

  const {freePackage} = useSelector((data: RootState) => data.shoppingCart);

  const { siteConfig } = useSelector((data: RootState) => data.config.config);

  return (
    <div
      className={`header ${!shoppingCartOpen && 'header-closed'}`}
      onClick={() => !checkout && setShoppingCartOpen((prev) => !prev)}
    >
      {
        siteConfig?.logoSmall ? <img src={siteConfig?.logoSmall} className="logo" /> :<LogoSvg className="logo"/>
      }
      {shoppingCartOpen ? (
        <>
          {
            freePackage ? t.selectedItems:t?.shoppingCart
          }
        </>
      ) : (
        <>
          <div>{t?.myVacation}</div>
        </>
      )}
    </div>
  );
};

export default ShoppingCartHeader;
