import React from 'react';
import { FaUmbrellaBeach } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Room, ShoppingCart } from '../interfaces';
import { TiDelete } from 'react-icons/ti';
import { IoClose } from "react-icons/io5";
import PersonSVG from '../../RoomPricing/assets/PersonSVG';

interface Props {
  shoppingCart: ShoppingCart;
  checkout: any;
  handleDeleteRoom: any;
}

const ShoppingCartRooms = ({
  shoppingCart,
  checkout,
  handleDeleteRoom,
}: Props) => {
  const { roomPricing: t, shoppingCartTranslations } = useSelector(
    (data: RootState) => data.config.translations
  );

  const {freePackage, settings} = useSelector((state: RootState) => state.shoppingCart);

  const getRoomDetail = (room: Room) => {
    let roomDetail = `${t?.[room.board]}`;
    if (room.adults == 1) {
      roomDetail += ` | ${t?.single}`;
    } else {
      roomDetail += ` | ${room.adults} ${t?.adults}`;
    }

    if (room.child > 0) {
      if (room.child == 1) {
        roomDetail += ` + ${t?.child}`;
      } else {
        roomDetail += ` + ${room.child} ${t?.children}`;
      }
    }

    if (room.infants > 0) {
      if (room.infants == 1) {
        roomDetail += ` + ${t?.infant}`;
      } else {
        roomDetail += ` + ${room.infants} ${t?.infants}`;
      }
    }
    return roomDetail;
  };

  return shoppingCart.rooms ? (
    <div className="rooms-activities">
      <b>{shoppingCartTranslations?.rooms}</b>
      {shoppingCart.rooms.map((room: any, i: number) => {
        return (
          <div key={i} className="product-line">
            <div className="activity">
              {/*<div className='occupiers'>*/}
              {/*  <div className='adult'>*/}
              {/*    <PersonSVG />*/}
              {/*    <span>*/}
              {/*      {`X${room.adults}`}*/}
              {/*    </span>*/}
              {/*  </div>*/}
              {/*  <div className='child'>*/}
              {/*    <PersonSVG />*/}
              {/*    <span>*/}
              {/*      {`X${room.child}`}*/}
              {/*    </span>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <span>
                <div className="name">{room.name}</div>
                <div className="board">{getRoomDetail(room)}</div>
              </span>
            </div>
            {shoppingCart.tp_id ? null : (
              <div className="price-delete">
                {!freePackage &&  <b>
                  {room.room_price}
                  {shoppingCart.currency_sign}
                </b>}
                {!checkout && (
                  <IoClose
                    size={15}
                    onClick={() => handleDeleteRoom(i)}
                    className="delete-product ease"
                  />
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  ) : null;
};

export default ShoppingCartRooms;
