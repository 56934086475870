import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import {ShoppingCart} from "../interfaces";

interface Props {
    shoppingCart: ShoppingCart,
}

const ShoppingCartTransfers = ({shoppingCart}:Props) => {
    const { shoppingCartTranslations:t } = useSelector(
        (data: RootState) => data.config.translations
    );

    return shoppingCart.is_include_transfer ?
         (
            <div className="transfers">
                <b>{t?.transfers}</b>
                {shoppingCart.transfer_type == "reg" ?
                    t?.reg_transfers :
                    t?.shuttle_transfers
                }
            </div>
        ) : null;
};

export default ShoppingCartTransfers;
