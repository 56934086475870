import React from 'react';

interface Props {
  className: string;
}

const LogoSvg = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.0"
      className={className}
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 289 385"
      xmlSpace="preserve"
    >
      <style type="text/css"></style>
      <g id="_x23_000000ff">
        <path d="M61.5,0h2.2c13.7,8.1,24.6,20.8,30.8,35.4c0.9,2.4,2.2,4.7,2.3,7.3c-0.9,1.8-3.3,1.1-3.6-0.8C87.9,26.3,77.2,12.5,63,3.7   C61.4,3.1,61.7,1.4,61.5,0z" />
        <path d="M144.1,3.3c1.6-0.2,4.1-1.4,4.9,0.8c0.4,2.3-2.5,2.2-4,2.7c-17.7,3.4-32.3,18.5-35.2,36.3c-0.3,1.4-0.5,4.1-2.7,3.3   c-2.1-0.5-0.8-3.1-0.7-4.6C109.7,22.9,125.3,7.1,144.1,3.3z" />
        <path d="M49.5,42.7c13.8-3.1,29,0.1,40.3,8.7c1.4,0.7,1.2,2.7-0.2,3.3c-1.6,0.1-2.8-1.3-4.1-2C71,42.8,50.7,42.5,36,52   c-1.5,0.8-3,2.6-4.9,1.7c-0.9-1.2-0.7-2.3,0.5-3.1C37,46.8,43.1,44.1,49.5,42.7z" />
        <path d="M128.1,42.2c12.3-2.6,25.9,2,33.7,11.9c1.3,2.2-2.3,3.6-3.3,1.5c-7.7-8.7-20.4-12.7-31.6-9.4c-4,0.9-7.5,3.2-11,5.3   c-2,1.2-3.5-2.2-1.4-3.1C118.5,45.3,123.2,43.3,128.1,42.2z" />
        <path d="M98.9,53.8c-1.4-2.1,2.1-4.1,3-1.7c7.2,16.5,11,34.2,13.4,52c38.6-3.9,78.8,10.9,105.2,39.4c1.6,1.2-1,4.1-2.2,2.4   c-5.6-5.2-11-10.5-17.2-14.9c-24.3-17.9-55.3-26.4-85.4-23.3c4.3,38.1,1.5,76.6-3.9,114.5c34.6-14.2,70.2-25.3,105.8-36.7   c1.2-0.2,3.5-1.3,3.8,0.7c0.6,1.8-1.4,2.2-2.6,2.7c-36.1,11.5-72.3,22.7-107.5,36.7c-1.4,7.4-2,15-2.8,22.5   c41.5,3.8,83.6,4,124.5,12.9c14.7,3.4,29.8,7.8,42,17c8.1,6,14.1,15.7,13.1,26c0.4,1.9-3.6,1.9-3.2,0c-0.3-5.6-1.4-11.4-4.9-16   c-7-9.6-18.4-14.6-29.3-18.5c-20.3-6.8-41.7-9.5-62.9-11.8c-26.5-2.7-53.2-3.6-79.7-6.1c-0.7,7.5-1.1,15.1-1.9,22.6   c0.8,0.4,1.5,0.8,2.3,1.1c-3.1,2.3-2.5,6.4-2.7,9.8c-0.9,19.5-0.9,39.1,0.7,58.6c0.7,0.3,1.4,0.7,2.2,1.1c-0.5,0-1.6,0-2.1,0   c0.3,2.9,0.6,5.8,0.9,8.7c38.1,6.5,79-6.1,106.6-33.3c1.6-1.5,3.1-3.1,4.9-4.4c1.8,0,2.7,2.3,1.1,3.4   c-28.2,30-71.6,44.5-112.2,37.9c1.3,9.4,3,18.7,5.5,27.9h-3.7c-2.6-9.3-4.2-18.9-5.5-28.5c-29.9-5.6-57.7-22.2-76.6-46.1   C11.2,289.8,1.6,263.9,0,237.5v-12.1c1.4-28.5,12.5-56.5,31.3-78.1c20.3-23.6,49.6-39,80.5-42.9C109.5,87.2,105.5,70,98.9,53.8    M40.7,142.7c-21.6,21.1-35,50.2-36.9,80.3c-2.1,30,7.4,60.6,26,84.2c18.1,23.5,45,39.9,74.1,45.6c-0.3-3.1-0.6-6.1-0.9-9.2   c-1.7-21.7-1.6-43.5-0.3-65.2c-0.6-1.2-1.5-1.7-2.8-1.7c1.2-0.6,3.2-0.6,3.2-2.4c0.1-7.8,1-15.6,1.6-23.4c-6.5-1-13.3-1.7-19.3-4.5   c-2-1-4.4-2.9-3.7-5.4c0.9-2.9,3.5-4.8,5.8-6.5c6.4-4.4,13.5-7.6,20.5-10.7c5.6-38.3,8.7-77.3,4.2-115.8   C85.4,111.4,59.9,123.7,40.7,142.7 M87.5,237.6c-1.6,1.2-3.8,3.9-1.5,5.4c5.7,3.5,12.6,3.6,19,4.6c0.8-6.9,1.3-13.7,2.5-20.5   C100.7,230.1,93.5,232.9,87.5,237.6z" />
        <path d="M114.4,60.4c3.6-1.5,8-0.3,11.8,0.4c13.6,3.2,24.7,15.4,26,29.4c0.5,2.2-3.4,2.6-3.4,0.3c-1.4-9.9-7.5-19.1-16.5-23.7   c-5-2.7-10.7-3.6-16.3-3.6C114.3,63.7,113.2,61.4,114.4,60.4z" />
        <path d="M89.3,65.4c1.6-0.5,4.7-2.1,5.1,0.7c-0.2,0.3-0.6,1.1-0.8,1.4c-6.6,2.3-13,6.1-17.1,12c-5.4,7.3-6.9,16.8-5.2,25.6   c0.5,1.9-2.7,2.5-3.2,0.8C63.7,89.6,73.5,71.1,89.3,65.4z" />
      </g>
      <g id="_x23_00ccffff">
        <path
          className="st0"
          d="M66,328.1c-22.9-14.7-40.4-37.7-48.1-63.8c25.4,11.6,54.3,17,82,12.4c1.3,0,2.2,0.5,2.8,1.7   c-1.3,21.7-1.4,43.5,0.3,65.2C89.9,340.6,77.3,335.4,66,328.1z"
        />
        <path
          className="st0"
          d="M136.1,268.1c32.6-5.2,65.9,1.8,96.3,13.7c-11,22.3-29.2,40.9-51.4,52.1c-22.1,11.3-47.8,15.1-72.2,10.8   c-0.7-0.4-1.4-0.7-2.2-1.1c-1.6-19.5-1.6-39.1-0.7-58.6c0.2-3.4-0.3-7.5,2.7-9.8C117.8,273.2,126.7,269.4,136.1,268.1z"
        />
      </g>
    </svg>
  );
};

export default LogoSvg;
