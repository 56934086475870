import React from 'react';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

const generateUUID = () => {
  const number = Math.floor(Math.random() * 10000000000000 + 1);
  return String('t' + number);
};
const useHandleCheckout = () => {
  const { shoppingCart } = useSelector(
    (state: RootState) => state.shoppingCart
  );
  const { settings } = useSelector((state: RootState) => state.shoppingCart);

  const navigate = useNavigate();
  const handleCheckout = () => {
    const uuid = generateUUID();
    const prevData = localStorage.getItem('cart');
    const parsedPrevData = JSON.parse(prevData ?? '{}');
    localStorage.setItem(
      'cart',
      JSON.stringify({
        ...parsedPrevData,
        [uuid]: { shoppingCart, time: Date.now(), settings },
      })
    );
    navigate('/checkout/' + uuid);
  };

  return handleCheckout;
};

export default useHandleCheckout;
