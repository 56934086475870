import React from 'react';
import { FaUmbrellaBeach } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

const ShoppingCartEmpty = () => {
  const { shoppingCartTranslations } = useSelector(
    (data: RootState) => data.config.translations
  );

  return (
    <div className="content cart-empty">
      <FaUmbrellaBeach size={60} />
      <div>{shoppingCartTranslations?.yourShoppingCart}</div>
      <div className="empty-text">{shoppingCartTranslations?.isEmpty}</div>
    </div>
  );
};

export default ShoppingCartEmpty;
