export enum checkoutInfo {
  FULL = 'full',
  MAIN_ROOM_GUEST = 'main_room_guest',
  CUSTOMER_ONLY = 'customer_only',
}

export enum packagePriceOption {
  REG = 'reg',
  FREE = 'free',
  PAY_LATER = 'pay_later',
  SUBSIDIZED = 'subsidized',
}

export const attachmentLocation = 'uploads/Guest/thumbs/';
