const appConfig = {
    "siteConfig": {
        "defaultLang": "en",
        "direction": "rtl",
        "hotelId":"1",
        "languages": [
            {
                "code": "he",
                "dir": "rtl",
                "name": "עברית",
            },
            {
                "code": "en",
                "dir": "ltr",
                "name": "English",
            },
            {
                "code": "uk",
                "dir": "ltr",
                "name": "українська",
            },
        ],
        "name": "Club Hotel Loutraki - Special deals",
        "domain": "gr.familybreak.co.il",
        "defaultDate":  {
            "from": 30,
            "range": 30
        },

        "flightDestinations":[
            {value:"TLV", label:"Tel Aviv - TLV"},
            {value:"ATH", label:"Athens - ATH"},
            {value:"CND", label:"Mamaia - CND"},
            {value:"LCA", label:"Larnaca - LCA"},
            {value:"HFA", label:"Haifa - HFA"},
        ],

        "dateFormat":"DD/MM/YYYY",
        "logo":"/assets/images/hotel360/lut-logo.png",
        "logoSmall":"/assets/images/hotel360/lut-small.png",
        "boardBasis":[
            "full_board",
            "sleep_only",
            "breakfast_kidush",
            "all_include",
            "breakfast",
            "half_board",
            "half_board_kosher",
        ],
        "hotelStar":[
            '3', '4', '5'
        ],
        "destinations": {
            "local": [],
            "abroad":[
                    {
                        "label": "טאבה הייטס - סיני ",
                        "value": 3419
                    },
                    {
                        "label": "מאמאיה - רומניה",
                        "value": 3423
                    },
                    {
                        "label": "בוקרשט - רומניה",
                        "value": 3421
                    },
                    {
                        "label": "קפריסין הצפונית ",
                        "value": "LCA"
                    },
                    {
                        "label": "לימסול - קפריסין",
                        "value": 3425
                    },
                    {
                        "label": "איה נאפה - קפריסין",
                        "value": 3422
                    },
                    {
                        "label": "פרוטראס - קפריסין",
                        "value": 3428
                    },
                    {
                        "label": "אתונה - יוון ",
                        "value": 3400
                    },
                    {
                        "label": "לוטרקי - יוון ",
                        "value": 3418
                    },
                    {
                        "label": "עקבה - ירדן ",
                        "value": 3424
                    }
              ]
        },
        "occupancy": [
            { adult: 2, child: 0},
            { adult: 3, child: 0},
            { adult: 2, child: 1},
            { adult: 2, child: 2},
          ],
        "email": {
            "value": "info@sky-group.co.il",
            "title": "זמינים במייל",
        },
        "phone": {
            "value": "077-772-9055",
            "title": "התקשרו או כתבו לנו בוואטסאפ",
            "hours": "שעות פעילות 09:00-17:00"
        },
        "facebook":{
            "value":"https://www.facebook.com/familybreak.co.il/",
            "title":"פרגנו לנו בעוקב בפייסבוק"
        },
        "instagram":{
            "value":"https://www.instagram.com/familybreak.co.il/",
            "title":"פרגנו לנו בעוקב באינסטגרם"
        },
        "whatsapp": {
            "phone":"077-772-9055",
            "message":"",
        },
        "companyInfo": {
            "title":"Familybreak",
            "value":"מעניקה שירותי קרקע ומענה לקהל הישראלי המגיע לאודסה עם מגוון אטרקציות למשפחות,זוגות וצעירים",
            "address": "ויצמן 24, נתניה"
        }
    },
    "theme": {
        "primary":"#eac98d",
        "secondary": "#b29962",
        "tertiary": "#c2c2c2",
        "background": "#fff",
        "button": "btn-style-5",
        "fontFamily": "Heebo",
        "title": "title-style-2"
    },
    "layouts": {
        "default": {
            "topModules": [
                "Header",
            ],
            "bottomModules": [
                "Footer",
            ]
        },
        "noFooter": {
            "topModules": [

            ],
            "bottomModules": [
                "Footer"
            ]
        },
        "empty": {
            "topModules": [

            ],
            "bottomModules": [

            ]
        }
    },
    "pages": [
        {
            "path": "/",
            "modules": [
                "ProductFilterMainPage",
                "ConceptsList"
            ],
            "name": "Main Page",
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": ["/prom/:promSecret/package/:id",'/package/view','/package/:id', "/p/:alias","/event/:alias/","/event/:alias/:affiliate","/package/:id/:affiliate","/prom/:promSecret/package/:id/:affiliate"],
            "modules": [
                "PackagePage",
                "Activities"
            ],
            "name": "Package Info",
            "type": "packagePage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": ["/dynamic"],
            "modules": [
               "HotelPage"
            ],
            "name": "Hotel Info",
            "type": "HotelPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": ["/prom/:promSecret", "/prom/:promSecret/:affiliate"],
            "modules": [
                "PromoPage",
            ],
            "name": "Main Page",
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/concept/:conceptCode/:conceptTitle",
            "modules": [
                "ConceptPage",
            ],
            "name": "Main Page",
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/room/:id",
            "modules": [
                "ImgCarousel",
                "SearchDatePicker",
                "RoomInfo",
                "OrderNow",
                "Activities",
                "RoomGeneralDetails"
            ],
            "name": "Room Info",
            "type": "roomPage",
            "layout": "empty",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        // {
        //     "path": "/hotels/:hotelId",
        //     "modules": [
        //         "PackageDetails",
        //         "PackageRoomPricing",
        //     ],
        //     "name": "Package Info",
        //     "type": "HotelPage",
        //     "layout": "default",
        //     "seo": {
        //         "title": "",
        //         "desc": ""
        //     }
        // },
        {
            "path": "/checkout/:id",
            "modules": [
                "Checkout",
            ],
            "name": "Checkout",
            "type": "checkoutPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/order/manage",
            "modules": [
                "ManageOrder",
            ],
            "name": "Manage Order",
            "type": "orderPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/contact",
            "modules": [
                "SearchDatePickerMainPage",
                "ContactUs",
            ],
            "name": "ContactUs",
            "type": "ContactUsPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/about",
            "modules": [
                "SearchDatePickerMainPage",
                "PageContentAboutUs",
            ],
            "name": "AboutUs",
            "type": "AboutUsPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/takanon",
            "modules": [
                "SearchDatePickerMainPage",
                "PageContentTakanon",
            ],
            "name": "Takanon",
            "type": "ContentPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/passenger-info",
            "modules": [
                "SearchDatePickerMainPage",
                "PageContentPassenger",
            ],
            "name": "PassengerInfo",
            "type": "ContentPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/hotel/:id/:name",
            "modules": [
                "HotelPage",
                // "HotelDetails",
                // "HotelRoomPricing",
            ],
            "name": "Package Info",
            "type": "packagePage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            },

        },
        {
            "path": "/destinations",
            "modules": [
                "SearchDatePickerMainPage",
                "DestinationList",
            ],
            "name": "Destination Page",
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path":"/flight/view/:type/:flightId",
            "modules": [
                "SearchDatePickerFlightViewPage",
                // flag
                "ViewFlight",
            ],
            "name": "NotFound404",
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/404",
            "modules": [
                "NotFound404",
                // "SearchDatePickerMainPage",
            ],
            "name": "NotFound404",
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
    ],
    "modulesConfigs": {
        "Header": {
            "links": [
                {
                    "text": "עמוד הבית",
                    "url": "/"
                },
                {
                    "text": "חבילות נופש",
                    "url": "/packages"
                },
                {
                    "text": "מי אנחנו",
                    "url": "/about"
                },
                // {
                //     "text": "על הצימר",
                //     "url": "/bandb"
                // },
                // {
                //     "text": "החדרים",
                //     "url": "/rooms"
                // },
                // {
                //     "text": "אטרקציות",
                //     "url": "/attractions"
                // },
                {
                    "text": "צור קשר",
                    "url": "/contact"
                }
            ],
            "theme": {
                "tertiary": "#000",
                "primary": "#b29962",
                "background": "#fff"
            },
            "set": "HeaderSet3",
            "logo": "/assets/images/hotel360/lut-logo.png",
            "widgetLangs": {
                "isActive": true,
            },
            "widgetSocial": {
                "isActive": false,
                "socials": [
                    {
                        "key": "instagram",
                        "value": "https://www.instagram.com/familybreak.co.il/",
                    }, {
                        "key": "facebook",
                        "value": "https://www.facebook.com/familybreak.co.il/"
                    }, {
                        "key": "whatsapp",
                        "value": "whatsapp://send?text=ברוכים הבאים למענה של פמילי ברייק!&phone=+972777729055",
                    }
                ],
            },
            "module": "Header"
        },
        "Footer": {
            "columns": [
                {
                    "header": "צור קשר",
                    "links": [
                        {
                            "text": "כתבו לנו",
                            "url": "/contact"
                        },
                        {
                            "text": "מי אנחנו?",
                            "url": "/about"
                        },
                        {
                            "text": "תקנון האתר",
                            "url": "/takanon"
                        },
                        {
                            "text": "מידע כללי לנוסע",
                            "url": "/passenger-info"
                        }
                    ]
                },
            ],
            "set": "FooterSet2",
            "module": "Footer",
            "widgetScrollToTop": {
                "isActive": true
            },
            "logo": "/assets/images/hotel360/lut-logo.png",
            "theme": {
                "background": "#b29962",
                "tertiary": "#000"
            }
        },
        "SearchDatePickerMainPage": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1"
            },
            "set": "SearchDatePickerSet2",
            "showSearchResults":{
                "isActive":false
            },
            'searchOptions':['hotel', 'package', 'flights'],
            "backgroundImg": "/assets/images/searchDatepickerPackground.jpg",
            "widgetDatePickerCarousel": {
                "isActive": false
            },
            "widgetVacationTypes": {
                "isActive": false
            },
        },
        "DestinationListLight":{
            "set": 'DestinationListLightSet3',
            "linkType": 'packageSearch',
            "title": 'היעדים שלנו',
        },
        // flag
        "FlightList":{
            "title": 'FlightList title',
            "destination": ['TLC', 'CND'],
            "dates": ['04/01/2024', '13/01/2024'],
            "flightImg": "/assets/images/PhotoDestinasion.png",
            "airlineName": "/assets/images/Airlines_blueAir_Logo",
            "airlineImg": "/assets/images/Airlines_blueAir_Logo",
        },
        "DestinationList":{
            "set": 'DestinationListSet1',
            "linkType": 'packageSearch',
            "title": 'היעדים שלנו',
        },
        "BannerLarge": {
            "module": "BannerLarge",
            "set": "BannerSet1",
            "img": "/assets/images/famflight.png",
            "button": false,
            "contentWidth": true,
            "backSize": "contain",
        },
        "BannerLargeGirl": {
            "module": "BannerLarge",
            "set": "BannerSet2",
            "key": "BannerLargeGirl",
            "img": "/assets/images/baby-girl.png",
            "button": false,
            "textLine1":"נופש בחגים זה אנחנו.",
            "theme":{
                background:"#00CCFF"
            }
        },
        "BannerLargePalms": {
            "module": "BannerLarge",
            "set": "BannerSet3",
            "key": "BannerLargePalms",
            "img": "/assets/images/bannerPalms.png",
            "contentWidth": true,
            "button": true,
            "buttonText": "תנו קפיצה לקיץ",
            "textLine1":"נופש בחגים זה אנחנו.",
        },
        "ImgCarousel": {
            "carousel": [
                {
                    "img": "https://www.africanbusinessreview.co.za/wp-content/uploads/2021/06/take-a-vacation.jpeg",
                    "text": "צימר רמת הגולן המקום המושלם."
                },
                {
    "img": "https://cdn2.hubspot.net/hubfs/439788/Blog/Featured%20Images/Best%20Hotel%20Website%20Designs.jpg",
                    "text": "צימר רמת הגולן המקום המושלם."
                }
            ],
            "theme": {
                "title": "title-style-1"
            },
            "module": "ImgCarousel"
        },
        "DatesSuggestion": {
            "buttonText": "לעוד דילים טובים ›",
            "url": "#",
            "title": "תאריכי חופשה",
            "titleLineTwo": "פופולאריים",
            "theme": {
                "title": "title-style-2"
            },
            "module": "DatesSuggestion"
        },
        "Description": {
            "img": "https://www.africanbusinessreview.co.za/wp-content/uploads/2021/06/take-a-vacation.jpeg",
            "description": "המקום המושלם לחופש, שקט והנאה למשפחות.",
            "theme": {
                "title": "title-style-2"
            },
            "module": "Description"
        },
        "ContactUs": {
            "img": "https://www.africanbusinessreview.co.za/wp-content/uploads/2021/06/take-a-vacation.jpeg",
            "set":"ContactUsSet2",
            "title":"צרו איתנו קשר.",
            "titleLineTwo": "צרו איתנו קשר.  נשמח לעזור לכם להזמין חופשה מושלמת.",

            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "ContactUs"
        },
        "PageContentAboutUs": {
            "title":"צרו איתנו קשר.",
            "set":"PageContentSet1",
            "desc_id": 3,
            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "PageContent"
        },
        "HotelPage": {
             "PackageDetailsConfig": {
                "widgetFlights": {
                  "isActive": false,
                  "widgetSet": 'FlightsSet2',
                },
                widgetHotelInfo: {
                  "isActive": true,
                  "widgetSet": 'HotelInfoSet2',
                },
                widgetActivities: {
                  "isActive": true,
                  "widgetSet": 'ActivitiesSet2',
                },
                showDates: false,
              },
               "RoomPricingConfig" : {
                "set": 'RoomPricingSet2',
              }
        },
        "PackagePage": {
            "theme": {
                "tertiary": "black",
            },
             "PackageDetailsConfig": {
         
                "widgetFlights": {
                  "isActive": true,
                  "widgetSet": 'FlightsSet2',
                },
                widgetHotelInfo: {
                  "isActive": true,
                  "widgetSet": 'HotelInfoSet2',
                },
                widgetActivities: {
                    "theme": {
                        "button": "btn-style-5",
                        "tertiary": "#000000"
                    },
                  "isActive": true,
                  "widgetSet": 'ActivitiesSet2',
                },
                showDates: false,
              },
               "RoomPricingConfig" : {
                "set": 'RoomPricingSet2',
              }
        },
        "PageContentTakanon": {
            "title":"תקנון האתר",
            "set":"PageContentSet1",
            "desc_id": 1,
            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "PageContent"
        },
        "PageContentPassenger": {
            "title":"מידע לנוסע",
            "set":"PageContentSet1",
            "desc_id": 2,
            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "PageContent"
        },
        'ProductFilterMainPage':{
            "module": "SwitchDatePicker",
            'searchOptions':['hotelOnly', 'hotelWithFlight'],
            "showSearchResults":{
                isActive:false
            },
            'title': "Your vacation start here!",
            "backgroundImg": "/assets/images/360/Background.jpg",
            "theme":{
                // "primary":"#0366ff"
            },
        },
        'ConceptsList':{
            "module": "ConceptsList",
            "theme":{
                "tertiary":"#e4e4e4"
            },
            "hotelId":'1',
            "conceptLimit":"3",
            "packageLimit":"5",
            "packageBoxStyle":"packageBoxWithGallery"
        },
        'ProductFilterFlightSearchPage':{
            "module": "SwitchDatePicker",
            'searchOptions':['flights'],
            "showSearchResults":{
                isActive:true
            },
            "theme":{
                "primary":"#00b6e4"
            }
        },
        'ProductFilterPackageSearchPage':{
            "module": "SwitchDatePicker",
            'searchOptions':['packages'],
            "showSearchResults":{
                isActive:true
            },
            "showFilters": {
                isActive: true
            }
        },
            'SearchDatePickerWithSwitcher':{
                "module": "SearchDatePicker",
                "theme": {
                    "title": "title-style-1",
                    "background": "#00ccff33"
                },
                'searchOptions':[ 'packages', 'flights', "hotels"],
                "set": "SearchDatePicketSwitcher",
                'searchResultType' : "packages",

                "autoSearch":false,
                "showSearchResults":{
                    isActive:false
                },
                "widgetDatePickerCarousel": {
                    isActive: false
                },
                "widgetVacationTypes": {
                    isActive: false
                },
            },
            'SearchDatePickerFlightViewPage':{
                "module": "SearchDatePicker",
                "theme": {
                    "title": "title-style-1",
                    "background": "#00ccff33"
                },
                'searchOptions':[],
                "set": "SearchDatePicketSwitcher",
                'searchResultType' : "flights",
                "autoSearch":false,
                "showSearchResults":{
                    isActive:false
                },
                "widgetDatePickerCarousel": {
                    isActive: false
                },
                "widgetVacationTypes": {
                    isActive: false
                },
            },
        "SearchDatePickerSearchPackagePage": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1",
                "background": "#00ccff33"
            },
            "searchResultType": "packages",
            'searchOptions': [],
            "set": "SearchDatePickerPackageIndex",
            "setConfig": {
                "promotionText": "בחרו מספר יעדים  בין תאריכים ונמצא לכם את החופשה המושלמת!",
            },
            "showSearchResults":{
                isActive:true
            },
            "widgetDatePickerCarousel": {
                isActive: false
            },
            "widgetVacationTypes": {
                isActive: false
            },
        },
        "SearchDatePickerFlightPage": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1",
                "background": "#00ccff33"
            },
            'searchResultType' : "flights",
            'searchOptions': [],
            'autoSearch':true,
            "set": "SearchDatePicketSwitcher",
            "setConfig": {
                "promotionText": "בחרו מספר יעדים  בין תאריכים ונמצא לכם את החופשה המושלמת!",
            },
            "showSearchResults":{
                isActive:true
            },
            "widgetDatePickerCarousel": {
                isActive: false
            },
            "flightSummary":{
                isActive: true
            },
            "widgetVacationTypes": {
                isActive: false
            },
        },
        "SearchDatePickerHotelPage": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1",
                "background": "#00ccff33"
            },
            'searchOptions':[],
            "set": "SearchDatePicketSwitcher",
            'searchResultType' : "hotels",
            "setConfig": {
                "promotionText": "בחרו מספר יעדים  בין תאריכים ונמצא לכם את החופשה המושלמת!",
            },
            "showSearchResults":{
                isActive:true
            },
            "widgetDatePickerCarousel": {
                isActive: false
            },
            "flightSummary":{
                isActive: true
            },
            "widgetVacationTypes": {
                isActive: false
            },
        },
        "NotFound404":{
            "image":"/assets/images/404.png",
            "title":'אופס.. הדף שחיפשת לא קיים',
            "redirect_title":'הנך מועבר לדף הבית',
            "redirect_seconds":7000,
        },
        "RoomInfo": {
            "theme": {
                "title": "title-style-1"
            },
            "set": "RoomInfoSet1",
            "widgetRoomDetails": {
                "isActive": true
            },
            "widgetRoomFacilities": {
                "isActive": true
            },
            "widgetRoomPictures": {
                "isActive": true
            },
            "facilities": [
                "TV",
                "Telephone",
                "Card",
                "Bed",
                "Radio",
                "BreakFast",
                "Porter",
                "FiveStar",
                "Juice",
                "HouseKeeping",
                "DoNotDisturb",
                "Hostess"
            ],
            "module": "RoomInfo"
        },
        "OrderNow": {
            "theme": {
                "background": "#42595d"
            },
            "module": "OrderNow"
        },
        "ActivitiesSuggestion": {
            "buttonText": "לכל האטרקציות ›",
            "url": "#",
            "title": "אטרקציות וחוויות",
            "titleLineTwo": "בקרבת מקום",
            "theme": {
                "title": "title-style-3"
            },
            "module": "ActivitiesSuggestion"
        },
        "SearchDatePicker": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1"
            },
            "set": "SearchDatePickerSmallPackage",
            "showSearchResults":{
                "isActive":true
            },
            "widgetDatePickerCarousel": {
                "isActive": false
            },
            "widgetVacationTypes": {
                "isActive": false
            },
        },
        "PromoPage": {
            "module": "PromoPage",
            "packageListConfig": {
                "set": "PackageListViewSet2",
                "stylesName": "packageBox3",
                "backgroundType":"type1",
                "widgetFilterMenu": {
                    "isActive": false,
                },
                "widgetMorePackages": {
                    "isActive": false
                },
                "widgetPackageBoxHeader": {
                    "isActive": true,
                    "title": ""
                }
            },
        },
        "ConceptPage": {
            "module": "ConceptPage",
            "conceptListConfig": {
                "backgroundType":"type1",
            },
        },
        "PackageListView": {
            "set": "PackageListViewSet2",
            "stylesName": "packageBox3",
            "module": "PackageListView",
            "backgroundType":"type1",
            "defaultFilters":{
                "staticFilter": "type=indexList",
                "limit":3,
                "dates": {
                    "from": 1,
                    "range": 14
                }
            },
            "widgetFilterMenu": {
                "isActive": false,
            },
            "widgetMorePackages": {
                "isActive": false
            },
            "widgetPackageBoxHeader": {
                "isActive": true,
                "title":"חבילות הנופש הנצפות ביותר"
            }
        },
        "PackageListViewFilters": {
            set: "PackageListViewSet2",
            "stylesName": "packageBox3",
            "module": "PackageListView",
            "theme": {
                "button": "btn-style-5",
            },
            "backgroundType":false,
            "widgetFilterMenu": {
                "isActive": true,
                "listFilters": [
                    {
                        "name": "מאמיה",
                        "filters": {
                            "dates": "14/07/2023-01/09/2023",
                            "limit": "6",
                            "destination":"3423"
                        }
                    },
                    {
                        "name": "קפריסין הצפונית",
                        "filters": {
                            "dates": "01/05/2023-01/09/2023",
                            "limit": "6",
                            "destination":"3396"
                        }
                    },
                    {
                        "name": "מחירים מיוחדים יולי",
                        "filters": {
                            "source": "alp",
                            "feature": "cruise",
                            "dates": "01/07/2023-01/08/2023",
                            "limit": "12",
                        }
                    },
                    {
                        "name": "חופשה עם הילדים",
                        "filters": {
                            "dates": "01/08/2023-01/09/2023",
                            "limit": "12",
                        }
                    }

                ],
                "title":"חבילות נופש לכל המשפחה",
                "titleLineTwo":"ביעדים והמלונות שלנו!"
            },
            "widgetMorePackages": {
                "isActive": true
            },
            "widgetPackageBoxHeader": {
                "isActive": false
            }

        },
        "PackageListViewNextWeek": {
            "set": "PackageListViewSet2",
            "stylesName": "packageBox4",
            "module": "PackageListView",
            "backgroundType":false,
            "defaultFilters":{
                "staticFilter": "type=upcoming",
                "limit":3,
                "dates": {
                    "from": 7,
                    "range": 21
                }
            },
            "widgetFilterMenu": {
                "isActive": false,
            },
            "widgetMorePackages": {
                "isActive": false
            },
            "widgetPackageBoxHeader": {
                "isActive": true,
                "title":"כמה אתם ספונטנים?"
            }
        },
        "PackageDetails": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "#000000"
            },
            "widgetFlights": {
                "isActive": true,
                "widgetSet": "FlightsSet2",
            },
            "widgetHotelInfo": {
                "isActive": true,
                "widgetSet": "HotelInfoSet2",
            },
            "widgetActivities": {
                "isActive": true,
                "widgetSet": "ActivitiesSet2",
            }

        },
        "HotelDetails": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "black",
                "background": "#00ccff33"
            },
            "widgetHotelInfo": {
                "isActive": true,
                "widgetSet": "HotelInfoSet1",
            },
            "widgetDatePicker": {
                "isActive": true,
                "widgetSet": "HotelDatePickerSet1",
            },
        },
        "RoomPricing": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "black",
            },
        },
        "HotelRoomPricing": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "black",
                "background": "#00ccff33"
            },
        },
        "PackageRoomPricing": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "black",
            },
            "set":"RoomPricingSet2"
        },
        "Activities": {
            "theme": {
                "tertiary": "black"
            },
            "set": "ActivitiesSet1",
            "module": "Activities"
        },
        "ViewFlight":{
            "theme":{
                "tertiary": "#ccc",
                "background":"#E1F8FC"
            }
        }
    }
};
export default appConfig;