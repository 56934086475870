const translations = {
    "status": "success",
    "data": {
        "lang": "he",
        "app": "hotel",
        "translation": {
            "crud": {
                "validationFailed" : "Validation failed",
                "operationError" : "Error occurred",
                "emptyList" : "No Data Found",
                "failToLoad" : "Error occurred on loading",
                "modelNotFound" : "Not Found",
            },
            "contactUs": {
                "email": "אימייל",
                "phone": "טלפון",
                "fullName": "שם מלא",
                "writeUsMessage": "כתבו לנו הודעה",
                "send": "שלח",
                "phoneRequired": "נא להזין מספר טלפון",
                "emailRequired": "נא להזין כתובת אימייל",
                "fullNameRequired": "נא להזין שם מלא",
                "messageRequired": "נא להזין הודעה",
                "aboutUs":'עלינו'
            },
            "cart": {
                "addToCart": "הוסף לסל",
            },
            "customerSupport": {
                "customerService": "שירות לקוחות",
                "sendMessageAndGetNotified": "שלחו לנו הודעה והנציד יחזור אליכם בהקדם",
                "callUs": "צלצלו אלינו",
            },
            "roomInfo": {
                "info": "תאור החדר",
                "viewAllThePhotos": "צפו בכל התמונות",
                "roomSize": "מ״ר - גודל חדר",
                "peopleInTheRoom": "אנשים בחדר",
                "doubleBed": "מיטה זוגית",
                "flights":"טיסות",
            },
            "conceptsList":{
                "conceptsListTitle":"חבילות הנופש שלנו, מהיום רוכשים מלון עם אטרקציות!",
                "includesActivities":"כולל פעילויות",
                "includeInPackage":"החבילה כוללת",
                "nights":"לילות",
                "noPackagesFound":"לא נמצאו חבילות"
            },
            "viewFlight":{
                "error4305Title":"אופס, אזלו המקומות לטיסה",
                "error4305Content":`אנו מתנצלים, אך כנראה שנגמרו המקומות להצעה זו ולכן אין באפשרותנו להתקדם לרכישה.
                הנך מועבר לעמוד הראשי.`,
                "TC":"תנאים",
                "flights":"טיסות",
                "addBaggage":"הוסף מטען",
                "addTrolly":"הוסף טרולי",
                "somethingWetWrong":"משהו השתבש",
                "suitcasePrice":"מחיר מזוודה",
                "trollyPrice":"מחיר טרולי",
                "totalPerPassenger":"סך הכל לנוסע",
                "updatePassengers":"עדכן את הנוסעים"

            },
            "room": {
                "roomSize": "גודל החדר",
                "bedType": "סוג מיטה",
                "breakfastIncluded": "כולל ארוחת בוקר",
                "noAvailabilityOnSelectedDates": "אין זמינות לחדר זה בתאריכים הנבחרים"
            },
            "promotion": {
                "modelNotFound": "דף לא נמצא",
                "failToLoad": "לא הצלחנו לטעון את העמוד!",
            },
            "searchDatePicker": {
                "title":"הזמינו את חופשת החלומות שלכם",
                "hotelOnly":"חיפוש חופשה בתאריכים",
                "hotelWithFlight":"חיפוש חופשה כולל טיסה",
                "oneWay":"כיוון אחד",
                "roundTrip":"הלוך ושוב",
                "selectDate":"בחרו אתרים",
                "select_package_dates": "בחר תאריכי חבילה",
                "startDate":"תאריך התחלה",
                "endDate":"תאריך סיום",
                "flights":"חיפוש טיסות",
                "packages":"חבילות נופש",
                "hotels":"מלונות בחול",
                "israelPackage":"חופשה בארץ",
                "passengers":"נוסעים",
                "adults":"מבוגרים",
                "adult":"מבוגר",
                "child":"ילד",
                "children":"ילדים",
                "infant":"תינוק",
                "infants":"תינוקות",
                "infantsWarningMessage":"תינוק צריך להיות פחות משנתיים מיום הטיסה הלוך וחזור",
                "select":"בחר",
                "noResults":"אין תוצאות",
                "checkout":"בצע הזמנה",
                "regularPrice":"מחיר רגיל",
                "from":"מ",
                "purchase":"לִרְכּוֹשׁ",
                "selectNewFlight":"בחר טיסה חדשה",
                "search": "חיפוש",
                "rooms": "חדרים",
                "composition": "הרכבים",
                "chooseComposition": "בחרו הרכבים",
                "numberOfGuests": "כמות אורחים",
                "checkOut": "צ׳ק אאוט",
                "checkIn": "צ׳ק אין",
                "whatVacation": "איזה חופשה",
                "youWant": "אתם רוצים",
                "vacationFriendsWith": "חופשה  חברים עם",
                "familyVacation": "חופשה משפחתית",
                "romanticVacation": "חופשה רומנטית",
                "notAvailable": "אין זמינות",
                "roomAvailableDuringTheWeek": "זמינות חדרים במהלך השבוע",
                "followingDates": "תאריכים הבאים",
                "vacation":"חופשה",
                "abroad":"בחו״ל",
                "inIsrael":"בארץ",
                "destination":"כל היעדים",
                "chooseDestination":"בחרו יעדים",
                "findDreamVacation":"חפשו את החופשה המושלמת בקליק.",
                "searchResTitle":"בחרו את החבילה המתאימה עבורכם",
                "directFlight":"טיסה ישירה ",
                "activeFlight":"הטיסה מופעלת",
                "flightSearch":"חיפוש טיסה",
                "nights":"לילות",
                "until":"עד תאריך",
                "untilYouSearchForFlightDate":"עד חיפוש טיסות תאריך",
                "destinationFrom":"המראה",
                "packageSearch":"חיפוש חבילה",
                "destinationTo":"נחיתה",
                "selectDestination":"בחר יעד",
                "checkHotel":"מעבר להזמנה",
                "dynamicFlight":"בחור את הטיסות בעצמכם",
                "bundle": "טיסות מוגדרות מראש",
                "freeCancelation":"ביטול חופשי",

            },
            "specialDeal": {
                "closeTheDeal": "סגרו את הדיל ›",
                "endsIn": "מסתיים בעוד",
                "specialDealForTheWeekend": "דיל מיוחד לסופ״ש",
                "from": "מתאריך",
                "until": "עד תאריך"
            },
            "footer": {
                "backToTop": "חזור למעלה",
                "allRightsReserved": " © כל הזכויות שמורות, אין להעתיק תוכן מהאתר ללא רשות.",
                "developBy": "by SKY 360 Technology",
                "thePurchaseOnTheWebsiteIsSecure": "הרכישה באתר מאובטחת.",
                "talkToUs": "איך מדברים איתנו?",
                "address": "כתובת",
                "phone": "טלפון",
                "whatsapp": "וואטסאפ",
                "phoneAndWhatsapp": "טלפון או בוואטסאפ",
            },
            "packages": {
                "from": "מתאריך",
                "until": "עד תאריך",
                "breakfastIncluded": "כולל ארוחת בוקר",
                "includesMassageAndSpa": "כולל מסאג׳ וספא!",
                "lastRoom": "חדר אחרון!",
                "includesTransfers": "כולל העברות",
                "includesFlights": "כולל טיסות",
                "includesActivities": "כולל אטרקציות",
                "nights": "לילות",
                "withoutBaggage":"ללא כבודה",
                "directFlight":"טיסה ישירה",
                "oneStop":"עצירה אחת",
                "numOfStops":"עצירות",
                "flightDetails":"פרטי הטיסות",
                "flightFrom":" from -> to ",
                "flightBack":" from -> to ",
                "hours":"שעות",
                "flightNumber":"מס׳ הטיסה",
                "returnFlight":"טיסה חזור",
                "outboundFlight":"טיסה הלוך",
                "airLine":"חברת התעופה",
                "suitcaseWeight":" הטיסה כוללת מזוודה weight ק״ג",
                "trollyWeight":"  הטיסה כוללת טרולי weight ק״ג",
                "trollyWeight2":"weight ק״ג טרולי / תיק",
                "suitcaseWeight2":"weight ק״ג מזוודה",
                "included":"כלול",
                "notIncluded":"לא כלול",
                "addSuitcase":" להוספת מזוודה",
                "includedActivitiesTitle":"אטרקציות וטיולים בחבילה",
                "checkAllPictures":"הצג את כל התמונות",
                "affiliateWith": "בשיתוף עם",
                "addButtonDisabledTooltip": "הזמנה מוגבלת לכמות חדרים - לא ניתן להוסיף חדר נוסף. כדי לשנות חדר יש להסיר חדר מהעגלה",
                'holidaySchedule':"לו״ז הנופש",
                "passengers":"נוסעים",
                "clickForDetails":"לחצו כאן לפרטי האטרקציה",
                "includedInPackage":"כלול בחבילה!",
            },
            "roomPricing": {
                "addPassenger": "הוסף נוסע",
                "pricePerPerson": "מחיר לאדם",
                "pricePerInfant": "מחיר לתינוק",
                "composition": "הרכב",
                "adultsInTheRoom": "מבוגרים",
                "children": "ילדים",
                "adults":"מבוגרים",
                "single":"מבוגר יחיד",
                "child":"ילד",
                'selectInfantCount':"בחר מרשימה",
                'infant':"תינוק",
                '1infant':"תינוק אחד",
                '2infants':"2 תינוקות",
                'infants':"תינוקות",
                "noInfants":"ללא תינוקות",
                "all_include": "הכל כלול",
                "breakfast":"ארוחת בוקר",
                "activities":"סיור לבחירה",
                "half_board":"חצי פנסיון",
                "half_board_kosher":"חצי פנסיון כשר",
                "full_board" : "פנסיון מלא",
                "sleep_only" : "לינה בלבד",
                "breakfast_kidush" : "א.בוקר וא.ערב שישי",
                "addToCard": "הוסיפו להזמנה",
                "inPackageDates": "בתאריכי החבילה",
                "chooseRoom": "בחרו הרכב וחדר",
                "choosePassenger": "הוסיפו כמות נוסעים",
                "nights": "לילות",
                "boardType":"בסיס לינה",
                "filters":"מסננים",
                "noAvailableRooms": "אין חדרים זמינים לתאריכים הנבחרים",
                "noAvailableRoomsForDates": "חדר לא זמין בתאריכים הנבחרים",
                "addInfant":"הוספת תינוק",
                "roomFacilities":"מתקני החדר"
            },
            "activitiesTranslations": {
                "addToOrder": "הוסיפו להזמנה",
                "passenger": "לנוסע",
                "showMore": "הצג עוד",
                "addAttractionsAndTrips": "הוסיפו אטרקציות וטיולים",
                "doYouWantToAddAttractions":" רוצים להוסיף אטרקציות לחופשה שלכם?",
                "itsTime":"זה הזמן!",
                "pricePerPerson":"מחיר לנוסע",
            },
            "modal": {
                "viewAllImages": "להציג את כל התמונות",
                "galleryTitle": "גלריית תמונות",
                "cancelationTerms": "תנאי ביטול - ",
                "nameOfTheHotel": "פרטי המלון",
                "hotelInfo": "על המלון",
                "hotelWebsite": "קישור לאתר המלון",
            },
            "shoppingCartTranslations":{
                "flightPrice":"מחיר טיסה",
                "suitcase":"מזוודה",
                'trolly':"טרולי",
                "passengers":"נוסעים",
                "shoppingCartButtonTooltip":"יש לבחור הרכב ולהוסיף להזמנה",
                "totalPrice":"סה״כ לתשלום",
                "proceedToPayment":"המשך לתשלום",
                "placeOrder":"מעבר לרישום",
                "NoHotelWasChosenForTheVacation":" לא נבחר מלון לחופשה",
                "betweenDates":"בין התאריכים",
                "packageInformation":"פרטי החבילה",
                "rooms":"פירוט החדרים",
                "transfers":"העברות",
                "reg_transfers":"העברות / שאטלים למלון הלוך ושוב",
                "shuttle_transfers":"שאטלים מהגבול למלון וחזור",
                "flights":"טיסות",
                "activities":"אטרקציות",
                "shoppingCart":"סל מוצרים",
                "package":"לילות במלון",
                "nights":"לילות",
                "inHotel":"במלון",
                "apply":"אישור",
                "promoCode":"קוד קופון",
                "yourShoppingCart":"סל הקניות שלכם",
                "isEmpty":"עדיין ריק...",
                "myVacation":"החופשה שלי",
                "showAll":"הצג הכל »",
                "beforeDiscount": "סה\"כ לפני הנחה -",
                "agentCommission": "עמלת סוכן -",
                "agentCommissionNet": "עמלת סוכן (תשלום נטו) -",
                "discountFromCode": "הנחת קוד -",
                "affiliateWith": "בשיתוף עם -",
                "tpId": "מזהה הזמנה -",
                "chargeTypeFullCC": "תשלום מלא - כולל עמלה",
                "chargeTypeNetCC": "תשלום עבור מחיר נטו",
                "chargeTypeCash": "עסקה במזומן",
                "agentCommissionPrec": "אחוז עמלת סוכן",
                "priceOff": "הנחה",
                "percentageOff": "אחוז הנחה",
                "selectedItems":"פריטים נבחרים",

            },
            "checkout":{
                "room": 'חדר',
                "personalInfo":"פרטי הנופשים",
                "paymentDetails":"פרטי התשלום",
                "orderCompletion":"סיום הזמנה",
                "userDetails":"פרטי המזמין",
                "name":"שם פרטי",
                "lastName":"שם משפחה",
                "passport":"מס׳ דרכון",
                "inEnlgish":"באנגלית",
                "birthdate":"תאריך לידה",
                "phone":"טלפון",
                "email":"דוא״ל",
                "notesForTheOrder":"הערות להזמנה",
                "namesOfGuests":"שמות הנוסעים",
                "namesMustMatchPassport":"* יש להקליד את כל השמות באנגלית בדיוק כפי שמופיעים בדרכון",
                "goToPayments":'שליחה ומעבר לתשלום',
                "saveOrder":'שמור הזמנה',
                "agreement":"אני מאשר/ת תקנון האתר, תנאי ההזמנה ואת תנאי ביטול ההזמנה",
                "men":"זכר",
                "women":"נקבה",
                "pleaseInputYourPhone":"נא להזין מספר טלפון",
                "pleaseInputYourLastName":"נא להזין שם המשפחה",
                'pleaseInputYourFirstName':"נא להזין את שמך הפרטי",
                "pleaseInputYourEmail":"נא להזין את המייל",
                "pleaseInputYourPassportNumber":"אנא הזן את מספר הדרכון שלך",
                "pleaseInputYourDateOfBirth":"נא להזין תאריך הלידה",
                "pleaseSelectGender":"נא לבחור מין",
                "maxChildAge":"ילד בין הגילאים 2-",
                "maxInfantAge":"תינוקות חייבים להיות מתחת לגיל שנתיים במועד החופשה",
                'pleaseAgreeWithT&C':"נא להסכים לתנאי השימוש ותקנון האתר",
                'viewTerms':"לצפיה בתקנון",
                "invalidEmail":"אימייל שגוי",
                "orderCreateSuccess": "הזמנתך נשמרה בהצלחה, הנך מועבר לתשלום",
                "orderCreateFree": "הזמנתך נשמרה בהצלחה!",
                "orderUpdateSuccess": "הזמנתך עודכנה בהצלחה!",
                "mainRoomGuestTitle" :"חדר על שם",
                "uploadPassportImage":"העלאת צילום דרכון",
                "addInfant":"הוסף תינוק",
                "updateInfant": "עדכון כמות תינוקות",
                "howManyInfantsDoYouWantToAdd":"בחר את כמות התינוקות לחדר זה",
                "add":"לְהוֹסִיף",
                "setInfantCountForRoom": "עדכן כמות תינוקות",
                "setInfantCountForRoomSuccess": "כמות התינוקות עודכנה בהצלחה",
                "infantsAddedToTheRoom":"תינוקות שנוספו לחדר",
                "selectNumberOfInfants":"בחר מספר תינוקות",
                "pricePerInfant":"מחיר לתינוק",
                "infantsRemoved":"הוסרו התינוקות לחדר זה",
                "requiredUploadPassportImage":"חובה להעלאת צילום דרכון",
                "infantError4403":"לא ניתן לשנות כמות תינוקות - צור קשר עם מוקד השירות",
                "docketId":"* Docket ID",
                "agencyId":"* Agency ID",
            },
            "manageOrder": {
                "moveToPayment": "הוסף אמצעי תשלום",
                "backToManage": "חזור לניהול הזמנה",
                "paymentTitle": "הוספת אמצעי תשלום",
                "manageTitle": "עדכון פרטי הנופשים",
                "modelNotFound": "הזמנה  לא נמצאה",
                "orderId":"ניהול הזמנה"
            },
            "completionForm": {
                "paymentFinish": {
                    "title": "ההזמנה בוצעה בהצלחה - התשלום הושלם",
                    "info": "מייל עם פרטי ההזמנה נשלח אליך לכתובת",
                },
                "waitingToApprove": {
                    "title": "ההזמנתך הועברה לאישור",
                    "info1": "פרטי ההזמנה נשמרו במערכת והועברו לאישור.",
                    "info2": 'תשלום התבצע רק לאחר אישור ההזמנה.',
                    "info3": 'מייל עם אישור ההזמנה ותשלום ישלח לאחר אישור ההזמנה.',
                },
                "paymentFail": {
                    "title": "התשלום נכשל",
                    "info1": "התשלום נכשל :(",
                    "infoFail": "לאחר מספר נסיונות, החיוב נכשל - אנה נסה שנית",
                    "infoWhatsapp": "במידה והתרחשה שגיאה, ואינך מצליח לשלם - יש ליצור קשר בוואטסאפ במספד: ",
                },
                "orderNum": "מספר ההזמנה",
                "thanksForChoose": "תודה שבחרת ב",
                "free": {
                    "title": "הרישום בוצע בהצלחה",
                    "info": "נשלח אליך מייל עם סיכום ההזמנה",
                } ,
                "pay_later": {
                    "title": "הרישום בוצע בהצלחה",
                    "info": "נשלח אליך מייל עם אישור ההזמנה",
                }
            },
            "paymentForm": {
                "creditNotSaveInBd": " פרטי האשראי אינם נשמרים האתר - הינך משלים תשלום בדרך מאובטחת",
                "title": "הזן אמצעי תשלום",
                "splitBtn": "לחץ לפיצול כרטיסי אשראי",
                "splitTitle": "פיצול כרטיסי אשראי",
                "splitDesc": "בחר את הכמות כרטיסי אשראי לפיצול ולחץ אישור",
                "noSplit": "ללא פיצול",
                "noPayments": "ללא תשלומים",
                "payments": "תשלומים",
                "paymentsNum": "מספר תשלומים",
                "withDebit": "עם קרדיט",
                "splitBy": "כרטיסי אשראי",
                "toPay": "לתשלום",
                "splitApprove": "אישור",
                "currencyRate": " חיוב מתבצע בשקלים - לפי שער תיירות",
                "splitCurrentCardNum": "פיצול אשראי",
                "errorMessageToRetry": "נסיון מס׳ {retryNum} - תשלום נכשל מול מחברת האשראי, יש להזין שוב את פרטי כרטיס אשראי או כרטיס אחר. אתר מקבל כרטיס VISA ו MASTERCARD בלבד.",
                "splitCurrentCardNumFrom": "מתוך",
            },
            "hotelDetails":{
                "updateDates":"עדכון תאריכים",
                "roomTypesToChooseFrom":"סוגי חדרים לבחירתך",
            },
            "general":{
                "packageSearchResult":"החבילות שמצאנו עבורך",
                "day":"יום",
                "selectDate":"בחרו אתרים",
                "search":"חיפוש",
                "price":"מחיר",
                "adults":"מבוגרים",
                "adult":"מבוגר",
                "child":"ילד",
                "children":"ילדים",
                "infant":"תינוק",
                "infants":"תינוקות",
                "noResults":"אין תוצאות",
                "nights":"לילות",
                "includesActivities":"{NUMBER} אטרקציות מתנה!",
                "includesActivity":"כולל אטרקציה מתנה!",
                "all_include": "הכל כלול",
                "breakfast":"ארוחת בוקר",
                "half_board":"חצי פנסיון",
                "half_board_kosher":"חצי פנסיון כשר",
                "full_board" : "פנסיון מלא",
                "sleep_only" : "לינה בלבד",
                "breakfast_kidush" : "א.בוקר וא.ערב שישי",
                "addToCard": "הוסיפו להזמנה",
                "chooseRoom": "בחרו הרכב וחדר",
                "boardType":"בסיס לינה",
                "filters":"מסננים",
                "rating":"דירוג מלון",
                "toDate" : "עד",
                "transfers" : "העברות",
                "from" : "החל מ-",
                "perPerson" : "לאדם",
                "include" : "כולל",
                "noIncludeText" : "חבילות נופש",
                "flights" : "טיסות",
                "vacationAt": "חופשה ב{DEST}",
                "fieldRequired":"שדה חובה",
            },
            "subsidizedForm":{
                "title":"הוספת סבסוד",
                "employee_id":"מספר עובד",
                "person_id":"ת.ז (9 ספרות)",
                "validate":"בדוק קוד עובד",
                "alreadyApplied":"הסבסוד כבר חל",
                "addOneMore":"הוסף סבסוד נוסף",
                "addOneMoreId":"הוסף עובד נוסף",
                "fieldRequired":"שדה חובה",
                "priceReducedBy":"מחיר מופחת ב",
                "forEmployee":"עבור עובד",
                "employeeIs":"זוהה בהצלחה ",
                "error4401":'נכשל בזיהוי, נסה שנית',
                "error4402":'קוד זוהה, אך כבר בשימוש',
                "guestIdentifiedId": 'מזוהה לפי ת.ז',
                "guestNotIdentified": 'יש להזדהות לפני רישום',
                "clickToIdentify":"לחץ להזדהות"
            },
            "uploadImage":{
                "uploadPassportImage":"העלאת צילום דרכון",
                "somethingWentWrong":"Something went wrong"
            },
            "flightButton":{
                "continueToOrder":"להמשך הזמנה",
                "lastPlace":"מקומות אחרונים",
                "pricePerPassenger":"מחיר לנוסע"
            }
        }
    }
};

export default translations;