const endpoints = {
  GET_AUTH_TOKEN: '/get-auth-token',
  GET_HOTELS_INDEX: '/supplier/hotel/index',
  GET_AREA_LIST: '/supplier/destination/dropdown',
  GET_FORM_RULES: '/supplier/hotel/rules',
  CREATE_HOTEL: '/supplier/hotel/create',
  UPDATE_HOTEL: '/supplier/hotel/update',
  GET_HOTEL_BY_ID: '/supplier/hotel/view/',
  GET_HOTEL_PROPERTIES: '/supplier/hotel/allPropsAndFacilities/',
  UPDATE_IMAGE_METADATA: '/supplier/hotel/updateImages?id=',
  GET_DESTINATIONS_INDEX: '/supplier/destination/index',
  CREATE_DESTINATION: '/supplier/destination/create',
  UPDATE_DESTINATION: '/supplier/destination/update',
  GET_DESTINATION: '/supplier/destination/view',
  GET_PROMOTIONS_INDEX: '/supplier/promotion/index',
  CREATE_PROMOTION: '/supplier/promotion/create',
  UPDATE_PROMOTION: '/supplier/promotion/update',
  GET_PROMOTION: '/supplier/promotion/view',

  DATE_RANGE_PRICE: {
    INDEX: '/supplier/date-price-range/index',
    GET: '/supplier/date-price-range/view',
    UPDATE: '/supplier/date-price-range/update',
    CREATE: '/supplier/date-price-range/create',
    DELETE: '/supplier/date-price-range/delete',
  },

  PRICE: {
    INDEX: '/supplier/price/index',
    SAVE: '/supplier/price/save',
  },

  // AFFILIATE
  GET_AFFILIATE_INDEX: '/supplier/affiliate/index',
  GET_AFFILIATE: '/supplier/affiliate/view',
  UPDATE_AFFILIATE: '/supplier/affiliate/update',
  DELETE_AFFILIATE: '/supplier/affiliate/delete',
  CREATE_AFFILIATE: '/supplier/affiliate/create',
  // Agency
  GET_AGENCY_INDEX: '/supplier/agency/index',
  GET_AGENCY: '/supplier/agency/view',
  UPDATE_AGENCY: '/supplier/agency/update',
  DELETE_AGENCY: '/supplier/agency/delete',
  CREATE_AGENCY: '/supplier/agency/create',

  AGENT_USER: {
    INDEX: '/supplier/agent-user/index',
    GET: '/supplier/agent-user/view',
    UPDATE: '/supplier/agent-user/update',
    CREATE: '/supplier/agent-user/create',
    // DELETE: '/supplier/agent-user/delete',
  },
  // Agent-REGISTRATION
  AGENT_INFO: '/supplier/agent-registration/tenant-info',
  REGISTER_AGENT: '/supplier/agent-registration/create-agency',

  // Airline
  GET_AIRLINE_INDEX: '/supplier/airline/index',
  GET_AIRLINE: '/supplier/airline/view',
  UPDATE_AIRLINE: '/supplier/airline/update',
  DELETE_AIRLINE: '/supplier/airline/delete',
  CREATE_AIRLINE: '/supplier/airline/create',
  AIRLINE_RULES: 'supplier/airline/rules',
  // Flight
  GET_FLIGHT_INDEX: '/supplier/flight/index',
  GET_FLIGHT_SERIES_INDEX: '/supplier/flight-series/index',
  GET_FLIGHT_SERIES_VIEW: '/supplier/flight-series/view',
  GET_FLIGHT: '/supplier/flight/view',
  UPDATE_FLIGHT: '/supplier/flight/update',
  DELETE_FLIGHT: '/supplier/flight/delete',
  CREATE_FLIGHT: '/supplier/flight/create',
  CREATE_FLIGHT_SERIES: '/supplier/flight-series/create',
  UPDATE_FLIGHT_SERIES: '/supplier/flight-series/update',
  DELETE_FLIGHT_SERIES: '/supplier/flight-series/delete',
  FLIGHT_RULES: 'supplier/flight/rules',
  FLIGHT_SERIES_RULES: 'supplier/flight-series/rules',
  GET_IMPORT_HISTORY_INDEX: '/supplier/flight-passenger/importHistory',
  GET_IMPORT_HISTORY: '/supplier/flight-passenger/importHistoryView',
  GET_FLIGHT_CLASS_INDEX:'/supplier/flight-class/index',
  GET_FLIGHT_CLASS:"/supplier/flight-class/view",
  CREATE_FLIGHT_CLASS:"/supplier/flight-class/create",
  UPDATE_FLIGHT_CLASS: "/supplier/flight-class/update",
  DELETE_FLIGHT_CLASS: "/supplier/flight-class/delete",
  FLIGHT_CLASS_RULES: "/supplier/flight-class/rules",

  ADD_CLASS_TO_FLIGHT_SERIES: "/supplier/flight-series/addFlightClass",
  UPDATE_CLASS_TO_FLIGHT_SERIES: "/supplier/flight-series/updateFlightClass",

  // AGENCY PAYMENTS
  GET_AGENCY_PAYMENT_INDEX: '/supplier/agency-payments/index',
  AGENCY_PAYMENT_SEND_MAIL:"supplier/agency-payments/send-proforma",
  CREATE_PROFORMA:"supplier/agency-payments/create-proforma",
  FLIGHT_IMPORT: {
    PARSE_PNL: 'supplier/pnl/parse',
    IMPORT_PASSENGERS: '/supplier/flight-passenger/import',
    RERUN_IMPORT: '/supplier/flight-passenger/RerunImportHistory',
    EXPORT_TO_DCS:'supplier/pnl/export-flight'
  },
  GET_PASSENGERS:'supplier/flight-passenger/index',
  GET_PASSENGER: '/supplier/flight-passenger/view',
  UPDATE_PASSENGER: '/supplier/flight-passenger/update',
  DELETE_PASSENGER: '/supplier/flight-passenger/delete',
  GET_PASSENGER_FORM_RULES: '/supplier/flight-passenger/rules',

  //User
  GET_USER_INDEX: '/supplier/user/index',
  GET_USER: '/supplier/user/view',
  UPDATE_USER: '/supplier/user/update',
  DELETE_USER: '/supplier/user/delete',
  CREATE_USER: '/supplier/user/create',


  // SUPPLIER
  GET_SUPPLIER_INDEX: '/supplier/supplier/index',
  GET_SUPPLIER_RULES: '/supplier/supplier/rules',
  GET_SUPPLIER: '/supplier/supplier/view',
  UPDATE_SUPPLIER: '/supplier/supplier/update',
  DELETE_SUPPLIER: '/supplier/supplier/delete',
  CREATE_SUPPLIER: '/supplier/supplier/create',

  GET_HOTEL_ROOMS: '/supplier/room/index',
  CREATE_ROOM: '/supplier/room/create',
  UPDATE_ROOM: '/supplier/room/update',
  GET_ROOM: '/supplier/room/view',
  UPDATE_GALLERY: '/supplier/hotel/updateImages',
  UPLOAD_IMAGE: 'supplier/image/upload',
  GET_GALLERY : 'supplier/image/gallery',
  DELETE_IMAGE: 'supplier/image/delete',
  UPDATE_IMAGE:'supplier/image/update',

  // Order
  GET_ORDER_INDEX: '/supplier/order/index',
  GET_ORDER: '/supplier/order/view',
  GET_ORDER_FILTER_DROPDOWNS: 'supplier/dictionary/dropdown',
  ATTRACTIONS: {
    INDEX: '/supplier/activity/index',
    GET: '/supplier/activity/view',
    UPDATE: '/supplier/activity/update',
    CREATE: '/supplier/activity/create',
    DELETE: '/supplier/activity/delete',
    RULES: '/supplier/activity/rules',
  },
  ATTRACTIONS_PRICE: {
    INDEX: '/supplier/activityprice/index',
    CREATE: '/supplier/activityprice/create',
    GET: '/supplier/activityprice/view',
    UPDATE: '/supplier/activityprice/update',
    DELETE: '/supplier/activityprice/delete',
    RULES: '/supplier/activityprice/rules',
  },
  CONCEPT: {
    GET: '/supplier/concept/view',
    INDEX: '/supplier/concept/index',
    CONCEPT_RULES: 'supplier/concept/rules',
    UPDATE: '/supplier/concept/update',
    CREATE: '/supplier/concept/create',
  },
  PACKAGE: {
    INDEX: '/supplier/package/index',
    GET: '/supplier/package/view',
    GET_SERIES: '/supplier/packageseries/view',
    INDEX_SERIES: '/supplier/packageseries/index',
    UPDATE: '/supplier/packageseries/update',
    CREATE: '/supplier/packageseries/create',
    GET_CONCEPTS: '/supplier/packageseries/getConcepts',
    CREATE_CONCEPT: '/supplier/packageseries/createConcept',
    DELETE: '/supplier/package/delete',
    DELETE_SERIES: '/supplier/packageseries/delete',
    ADD_HOTEL_TO_SERIES: '/supplier/packageseries/addHotel',
    ADD_ATTRACTION_TO_SERIES: '/supplier/packageseries/addAttractions',
    REMOVE_HOTEL_TO_SERIES: '/supplier/packageseries/removeHotel',
    REMOVE_ATTRACTION_TO_SERIES: '/supplier/packageseries/removeAttraction',
    GET_FLIGHTS_FOR_PACKAGE_SERIES: '/supplier/packageseries/GetAvailableFlights',
    POST_FLIGHTS_TO_PACKAGE_SERIES: '/supplier/packageseries/attachFlights',
  },
  MANAGEMENT: {
    GET_TENANT: '/supplier/tenant/view',
    UPDATE_TENANT: '/supplier/tenant/update',
    GET_SITE: "supplier/site/view",
    UPDATE_SITE: 'supplier/site/update',
    // PROBABLY
    CREATE_SITE:"supplier/site/create"
  },
  GROUP: {
    INDEX: '/supplier/group/index',
    GET: '/supplier/group/view',
    UPDATE: '/supplier/group/update',
    CREATE: '/supplier/group/create',
    DELETE: '/supplier/group/delete',
  },
  GROUP_MEMBER: {
    INDEX: '/supplier/group-member/index',
    GET: '/supplier/group-member/view',
    UPDATE: '/supplier/group-member/update',
    CREATE: '/supplier/group-member/create',
    DELETE: '/supplier/group-member/delete',
    POST:"/supplier/group-member/import?group_id="
  },
  DICTIONARY: {
    DROPDOWN: '/supplier/dictionary/dropdown'
  },
  REPORTS: {
    DASHBOARD_TODAY: '/supplier/report/today-data',
    DASHBOARD_LASTX: '/supplier/report/orders-sum-by',
  },
  AUTH: {
    USER_INFO: '/supplier/site/user-info',
    LOGIN: '/supplier/auth/login',
    LOGOUT: '/supplier/auth/logout',
    REFRESH_TOKEN: '/supplier/auth/refresh-token',
  },

};

export default endpoints;