import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { ShoppingCart } from '../interfaces';
import { IoClose } from "react-icons/io5";


interface Props {
  shoppingCart: ShoppingCart;
  handleDeleteActivity: any;
  isCheckoutPage: boolean;
}

const ShoppingCartActivities = ({
  shoppingCart,
  handleDeleteActivity,
  isCheckoutPage,
}: Props) => {
  const { shoppingCartTranslations: t } = useSelector(
    (data: RootState) => data.config.translations
  );

  const {freePackage} = useSelector((data: RootState) => data.shoppingCart);

  return shoppingCart.activities &&
    Object.keys(shoppingCart.activities).length > 0 ? (
    <div className="rooms-activities">
      <b>{t.activities}</b>
      {Object.keys(shoppingCart.activities).map((key) => {
        const activity = shoppingCart.activities[key];
        return (
          <div key={key} className="product-line">
            <div className="product-activity ">
              <span className="amount">{activity.amount}</span>
              <span className="amount-x">X</span>
              <span className="name">{activity.name}</span>
            </div>
            <div className="price-delete">
              { !freePackage &&
                <b>
                  {shoppingCart.currency_sign}{" "}
                  {activity?.min_price * activity.amount || 0}
              </b>
              }
              {activity?.min_price && !isCheckoutPage && (
                <IoClose
                  size={15}
                  className="delete-product"
                  onClick={() => handleDeleteActivity(key)}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  ) : null;
};

export default ShoppingCartActivities;
