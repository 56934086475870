import React from 'react';
import { FaUmbrellaBeach } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { ShoppingCart } from '../interfaces';

interface Props {
  shoppingCart: ShoppingCart;
}

const ShoppingCartMainProduct = ({ shoppingCart }: Props) => {
  const { shoppingCartTranslations } = useSelector(
    (data: RootState) => data.config.translations
  );

  return shoppingCart.hotel ? (
    <div className="main-product">
      <div className="dates">
        <b>{shoppingCartTranslations?.betweenDates}</b>
        <div className="title">{shoppingCart?.dates}</div>
      </div>

      <div className="package-info">
        <b>{shoppingCartTranslations?.packageInformation}</b>
        <div className="title">
          {shoppingCart.name} <br />
          {shoppingCart.nights} {shoppingCartTranslations?.nights}{' '}
          {shoppingCartTranslations?.inHotel} {shoppingCart.hotel.name}
        </div>
      </div>
    </div>
  ) : null;
};

export default ShoppingCartMainProduct;
