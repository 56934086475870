import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { ShoppingCart } from '../interfaces';
import FlightsSet1 from '../../sharedWidgets/Flights/FlightsSet1/FlightsSet1';
import { PackageSettingsInterface } from '../../PackageDetails/packageDataInterface';
import FlightsSet2 from "../../sharedWidgets/Flights/FlightsSet2/FlightsSet2";

interface Props {
  shoppingCart: ShoppingCart;
  packageSettings: PackageSettingsInterface;
}

const dummyFlights = [
  {
    from: {
      name: 'Paris',
      code: 'PAR',
    },
    to: {
      name: 'San Francisco',
      code: 'SFO',
    },
    airline: {
      id: 1,
      logo: 'logo1.png',
      name: 'Delta',
    },
    flight_code: 'DL468',
    is_direct: 1,
    num_of_stops: 0,
    stop_comment: 'Direct',
    date: '2024-01-31',
    baggage: {
      is_include_suitcase: 0,
      suitcase_weight: 22,
      is_include_trolly: 0,
      trolly_weight: 10,
    },
    flightDayHour: {
      start_day: 31,
      start_hour: '06:57',
      day_arrive: 3,
      hour_arrive: '06:57',
    },
  },
  {
    from: {
      name: 'Paris',
      code: 'PAR',
    },
    to: {
      name: 'San Francisco',
      code: 'SFO',
    },
    airline: {
      id: 1,
      logo: 'logo1.png',
      name: 'Delta',
    },
    flight_code: 'DL468',
    is_direct: 1,
    num_of_stops: 0,
    stop_comment: 'Direct',
    date: '2024-01-31',
    baggage: {
      is_include_suitcase: 0,
      suitcase_weight: 22,
      is_include_trolly: 0,
      trolly_weight: 10,
    },
    flightDayHour: {
      start_day: 31,
      start_hour: '06:57',
      day_arrive: 3,
      hour_arrive: '06:57',
    },
  },
];

const ShoppingCartFlights = ({ shoppingCart, packageSettings }: Props) => {
  const { shoppingCartTranslations: t } = useSelector(
    (data: RootState) => data.config.translations
  );

  return (shoppingCart.flights || []).length > 0 ? (
    <div className="flights">
      <b>{t?.flights}</b>
      <FlightsSet2 flights={shoppingCart.flights} packageSettings={packageSettings} />
    </div>
  ) : null;
};

export default ShoppingCartFlights;
