import moment from 'moment';
import React from 'react';
import { FaPlaneArrival, FaPlaneDeparture, FaSuitcase } from 'react-icons/fa';
import { MdLuggage } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import './FlightBox.less';
import { FlightView } from '../../../../SearchDatePicker/SearchDatePickerInterface';

interface Props {
  flight: FlightView;
  flightFrom: boolean;
}
const getHourDifference = (start: any, end: any) => {
  return moment
    .utc(moment.duration(start).subtract(moment.duration(end)).asMilliseconds())
    .format('hh:mm');
};

const FlightBox = ({ flight, flightFrom }: Props) => {
  const { packages: t } = useSelector(
    (data: RootState) => data.config.translations
  );
  const { siteConfig } = useSelector((state: RootState) => state.config.config);

  const flightIncludesTrollyAndSuitcase = (
    IncludesSuitcase: number,
    IncludesTrolly: number,
    suitcaseWeight: number,
    trollyWeight: number
  ) => {
    if (IncludesSuitcase === 0 && IncludesTrolly === 0) {
      return <div className="no-luggage">{t.withoutBaggage}</div>;
    } else if (IncludesSuitcase || IncludesTrolly) {
      return (
        <div className="luggage">
          {IncludesSuitcase === 1 && (
            <div>
              <FaSuitcase color="#000" size={35} />
              <span>{t.suitcaseWeight.replace('weight', suitcaseWeight)}</span>
            </div>
          )}
          {IncludesTrolly === 1 && (
            <div>
              <MdLuggage color="#000" size={35} />
              <span>{t.trollyWeight.replace('weight', trollyWeight)}</span>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div className="flight-box">
      <p className="header">
        {!flightFrom
          ? t.flightFrom
              .replace('to', flight.from.name)
              .replace('from', flight.to.name)
          : t.flightBack
              .replace('to', flight.from.name)
              .replace('from', flight.to.name)}
      </p>
      <div className="flight-details">
        <span className="plane">
          {!flightFrom ? (
            <FaPlaneDeparture size={45} />
          ) : (
            <FaPlaneArrival size={45} />
          )}
        </span>

        <span className="flight-to">
          <div className="flight-date">
            {moment(flight?.departure_date * 1000).format(
              siteConfig.dateFormat
            )}
          </div>
          <div className="location">{flight.from.code}</div>
        </span>
        <span className="duration">
          <div>
            <span>
              {getHourDifference(flight.arrival_date, flight.departure_date)}
            </span>
            <span>{t.hours}</span>
          </div>
          {flight.num_of_stops == 0 ? (
            <div>{t.directFlight}</div>
          ) : flight.num_of_stops == 1 ? (
            <div>{t.oneStop}</div>
          ) : (
            <div>{flight.num_of_stops + ' ' + t.numOfStops}</div>
          )}
          {flight.stop_comment ? <div>{flight.stop_comment}</div> : null}
        </span>
        <span className="flight-from">
          <div className="flight-date">
            {moment(flight.arrival_date * 1000).format(siteConfig.dateFormat)}
          </div>
          <div className="location">{flight.to.code}</div>
        </span>
      </div>
      <div className="additional-info">
        <div className="flight-number">
          <span>{flight.identifier.id}</span>
          <span>*{t.flightNumber}</span>
        </div>
        <div className="company">
          {flight.airline.image ? (
            <img src={flight.airline.image} />
          ) : (
            <span>{flight.airline.name}</span>
          )}
          <span>*{t.airLine}</span>
        </div>
      </div>
      <span className="baggage">
        {flightIncludesTrollyAndSuitcase(
          flight.baggage.is_include_suitcase,
          flight.baggage.is_include_trolly,
          flight.baggage.suitcase_weight ?? 0,
          flight.baggage.trolly_weight ?? 0
        )}
      </span>
    </div>
  );
};

export default FlightBox;
