import { themeInterface } from "../interfaces";

export interface componentStyles {
    button: string,
    input: string,
    title: string,
}

const classPriority = (theme: themeInterface, componentConfig: any): componentStyles => {
    return {
        button: componentConfig?.theme?.button || theme?.button,
        input: componentConfig?.theme?.input || theme?.input,
        title: componentConfig?.theme?.title || theme?.title,
    };
};

export default classPriority;