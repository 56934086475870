import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { RootState } from '../../../store/store';
import {Alert, Button, Col, Input, Row} from "antd";
import apiClient from '@skygroup/shared/API/apiClient';
import endpoints from "../../../utils/endpoints";
import { ApiStatus } from '@skygroup/shared/components/crud-helpers/ModelApiMessage';
import {updateCodeInfo} from "../ShoppingCartSlice";
import {agencyChargeTypes, ShoppingCart} from "../interfaces";

interface Props {
    t: any;
    shoppingCart: ShoppingCart;
    pageType: string;
}

const DiscountCode = ({t, shoppingCart, pageType}:Props) => {

    const [isLoadingDiscountCode, setIsLoadingDiscountCode] = useState(false);
    const [discountErrorMessage, setDiscountErrorMessage] = useState('');
    const [discountCode, setDiscountCode] = useState('');

    const dispatch = useDispatch();

    const handleDiscountCodeCheck = async () => {
        if (discountCode !== '') {
            setIsLoadingDiscountCode(true);
            try {
                const res = await apiClient
                    .post(endpoints.ORDER.CHECK_DISCOUNT_CODE, {'code': discountCode})
                    .then((res) => {
                        return res?.data;
                    })
                    .finally(() => {
                        setIsLoadingDiscountCode(false);
                    });

                if (res.status == ApiStatus.success) {
                    dispatch(updateCodeInfo({codeInfo: res.data}));
                } else {
                    setDiscountErrorMessage(res.data.message);
                    setTimeout(() => setDiscountErrorMessage(''), 4000);
                }
            } catch (error: any) {
                setDiscountErrorMessage("API Error");
            }
        }
    };

    const renderCodeInfo = () => {

        const codeInfo = shoppingCart.codeInfo;
        let info = [];
        let codeRes = null;

        if (pageType === "checkout") {

            if (codeInfo.type === "agent") {

                var chargeTypeText = "";
                switch (codeInfo.charge_type) {
                    case agencyChargeTypes.CHARGE_TYPE_CREDIT_CARD_FULL:
                        chargeTypeText = t.chargeTypeFullCC;
                        break;
                    case agencyChargeTypes.CHARGE_TYPE_CREDIT_CARD_NET:
                        chargeTypeText = t.chargeTypeNetCC;
                        break;
                    case agencyChargeTypes.CHARGE_TYPE_CASH:
                        chargeTypeText = t.chargeTypeCash;
                        break;
                }

                codeRes = <div id="code-info" className="agent-code">
                        <div className="agency-name">{codeInfo.agency_name} - {codeInfo.agent_name} </div>
                        <div className="percentage-off">{codeInfo.comission_percentage} {t.agentCommissionPrec}</div>
                        <div className="code-desc">{chargeTypeText}</div>
                    </div>;
            } else {
                codeRes = <div id="code-info" className="agent-code">
                    <div className="percentage-off">
                        {codeInfo.discount.price_off > 0 ?
                            `${codeInfo.discount.price_off}${shoppingCart.currency_sign} ${t.priceOff}` :
                            `${codeInfo.discount.percentage_off} ${t.percentageOff}`
                        }
                    </div>
                    <div className="code-desc">{codeInfo.discount.name} {t.affiliateWith} {codeInfo.model_related.name}</div>
                </div>;
            }

            // price section
            info.push(`${t.beforeDiscount} ${codeInfo.originalPrice}${shoppingCart.currency_sign}`);

            if (codeInfo.type === "agent") {
                if (codeInfo.charge_type === agencyChargeTypes.CHARGE_TYPE_CREDIT_CARD_FULL) {
                    info.push(`${t.agentCommission} ${codeInfo.discountPrice}${shoppingCart.currency_sign}`);
                } else {
                    info.push(`${t.agentCommissionNet} ${codeInfo.discountPrice}${shoppingCart.currency_sign}`);
                }
            } else {
                info.push(`${t.discountFromCode} ${codeInfo.discountPrice}${shoppingCart.currency_sign}`);
            }
        } else if (pageType === "manage-order") {
            if (codeInfo.discountPrice > 0) {
                info.push(`${t.discountFromCode} ${codeInfo.code} ${codeInfo.discountPrice}${shoppingCart.currency_sign}`);
            }
            if (shoppingCart.agency) {
                info.push(`${t.affiliateWith} ${shoppingCart.agency}`);

                if (shoppingCart.tp_id) {
                    info.push(`${t.tpId} ${shoppingCart.tp_id}`);
                }
            }
        }

        return <Row className="discount-code discount-code-info">
            {codeRes}
            <ul>
                {info.map( (value: string, key: number) => {
                    return <li key={key}>{value}</li>;
                })}
            </ul>
        </Row>;
    };

    return shoppingCart.codeInfo ? renderCodeInfo() : (
        <Row className="discount-code">
            <Col span={12}>
                <Input
                    placeholder={t.promoCode}
                    className="input"
                    onChange={e => setDiscountCode(e.target.value)}
                />
            </Col>
            <Col span={4}>
                <Button className="btn-style-5"  onClick={handleDiscountCodeCheck} loading={isLoadingDiscountCode}>
                    {t.apply}
                </Button>
            </Col>
            <Col span={20}>
                { discountErrorMessage && <Alert message={discountErrorMessage} type="error" />}
            </Col>
        </Row>
    );
};

export default DiscountCode;
