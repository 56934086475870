import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import shoppingCartSlice from '../modules/ShoppingCart/ShoppingCartSlice';
import packageDataSlice from '../modules/PackageDetails/packageDataSlice';
import searchSlice from '../modules/SearchDatePicker/searchDatePickerSlice';
import configSlice from './config';
import hotelSlice from '../modules/HotelDetails/hotelSlice';

const reducer = combineReducers({
  search: searchSlice,
  config: configSlice,
  package: packageDataSlice,
  shoppingCart: shoppingCartSlice,
  hotel: hotelSlice,
});

export const store = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
