import moment from 'moment';
import React from 'react';
import { FaPlaneArrival, FaPlaneDeparture, FaSuitcase } from 'react-icons/fa';
import { MdLuggage } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import {Baggage, Flight} from '../../../../ShoppingCart/interfaces';
import './FlightBox.less';
import ButtonWithIcon from '../../../../../components/Common/ButtonWithIcon';
import { FiPlus } from 'react-icons/fi';
import {Row, Tooltip} from 'antd';

interface Props {
    flight: Flight;
    flightFrom: boolean;
}
const getHourDifference = (start: any, end: any) => {
    return moment
        .utc(moment.duration(start).subtract(moment.duration(end)).asMilliseconds())
        .format('hh:mm');
};

const FlightBox = ({ flight, flightFrom }: Props) => {
    const { packages: t, general: gt } = useSelector((data: RootState) => data.config.translations);
    const { siteConfig } = useSelector((state: RootState) => state.config.config);

    const isOldFlight = flight.flightDayHour;
    const flightDuration = isOldFlight ? getHourDifference(
        flight.flightDayHour.hour_arrive,
        flight.flightDayHour.start_hour
    ) : flight.duration;

    const flightDepartureDate = isOldFlight ?
        moment(flight.date).format(siteConfig.dateFormat) :
        moment(flight.departure_date * 1000).utc().format(gt?.day + " ddd, " + siteConfig.dateFormat.replace("/YYYY",""));

    const flightDepartureHour = isOldFlight ?
        flight.flightDayHour.start_hour :
        moment(flight.departure_date * 1000).utc().format("HH:mm");

    const flightArrivalDate =  isOldFlight ? (
            flight.flightDayHour.start_day == flight.flightDayHour.day_arrive
                ? moment(flight.date).format(siteConfig.dateFormat)
                : moment(flight.date).add(1, 'days').format(siteConfig.dateFormat) ) :
        moment(flight.arrival_date * 1000).utc().format(gt?.day + " ddd, " + siteConfig.dateFormat.replace("/YYYY",""));

    const flightArrivalHour =   isOldFlight ?
        flight.flightDayHour.hour_arrive :
        moment(flight.arrival_date * 1000).utc().format("HH:mm");

    const flightIncludesTrollyAndSuitcase = (baggage: Baggage) => {
        return (
            <div className="luggage">
                <div>
                    <FaSuitcase
                        color="#000"
                        size={35}
                        opacity={baggage.is_include_suitcase ? 1 : 0.4}
                    />
                    <span>
                        {baggage.is_include_suitcase  ? (
                            <span className="include">{t.included}</span>
                        ) : (
                            <span className="not-include">{t.notIncluded}</span>
                        )}
                        {baggage.is_include_suitcase
                            ? t.suitcaseWeight2.replace('weight', baggage.suitcase_weight)
                            : ''}
                      </span>
                </div>
                <div>
                    <MdLuggage
                        color="#000"
                        size={35}
                        opacity={baggage.is_include_trolly ? 1 : 0.4}
                    />
                    <span>
                        {baggage.is_include_trolly ? (
                            <span className="include">{t.included}</span>
                        ) : (
                            <span className="not-include">{t.notIncluded}</span>
                        )}
                                    {baggage.is_include_trolly
                                        ? t.trollyWeight2.replace('weight', baggage.trolly_weight)
                                        : ''}
                      </span>
                </div>
                {(baggage.enable_sale_suitcase || baggage.enable_sale_rtolly) &&
                <ButtonWithIcon
                    Icon={FiPlus}
                    text={t.addSuitcase}
                    className="btn-style-2"
                />
                }
            </div>
        );
    };

    return (
        <div className={`flight-box-set-2 ` + (flightFrom ? "flight-outbound" : "flight-inbound")}>
            <p className="header">
                <div className="airline-name">
                    {flight.airline.logo ? (
                        <Tooltip placement={"right"} title={flight.airline.name}><img title={flight.airline.name}
                                                                                      src={flight.airline.logo}/></Tooltip>
                    ) : (
                        <span>{flight.airline.name}</span>
                    )}
                </div>
                <span className="flight-info">
                    <b> {flightFrom ? t.outboundFlight : t.returnFlight}</b>
                    <span className="flight-code">{flight.flight_code ?? flight.flight_number}</span>
                </span>
            </p>
            <div className="flight-details">
                <span className="plane">
                  <FaPlaneDeparture size={45}/>
                </span>
                <span className="flight-to">
                  <div className="flight-time">{flightDepartureHour}</div>
                  <div className="flight-date">{flightDepartureDate}</div>
                </span>
                <span className="duration">
                    <div>
                        <span>{t.hours}</span>
                        <span>{flightDuration}</span>
                    </div>
                    <div className="codes-wrapper">
                      <div className="location">{flight.from.code}</div>
                        {flight.num_of_stops == 0 ? (
                            <div>{t.directFlight}</div>
                        ) : flight.num_of_stops == 1 ? (
                            <div>{t.oneStop}</div>
                        ) : (
                            <div>{flight.num_of_stops + ' ' + t.numOfStops}</div>
                        )}
                        <div className="location">{flight.to.code}</div>
                    </div>
                </span>
                <span className="flight-from">
                  <div className="flight-time">{flightArrivalHour}</div>
                  <div className="flight-date">{flightArrivalDate}</div>
                </span>
                <span className="plane">
                <FaPlaneArrival size={45}/>
                </span>
            </div>
            <span className="baggage">
                {flightIncludesTrollyAndSuitcase(flight.baggage)}
          </span>

        </div>
    );
};

export default FlightBox;
