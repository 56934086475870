const appConfigFamilybreak = {
    "siteConfig": {
        "defaultLang": "he",
        "languages": [
            {
                "code": "he",
                "dir": "rtl",
                "name": "עברית",
            },
            {
                "code": "en",
                "dir": "ltr",
                "name": "English",
            },
            {
                "code": "uk",
                "dir": "ltr",
                "name": "українська",
            },
        ],
        "name": "Familybreak - חבילות נופש למשפחות",
        "domain": "gr.familybreak.co.il",
        "defaultDate":  {
            "from": 30,
            "range": 30
        },

        "flightDestinations":[
            {value:"TLV", label:"Tel Aviv - TLV"},
            {value:"ATH", label:"Athens - ATH"},
            {value:"CND", label:"Mamaia - CND"},
            {value:"LCA", label:"Larnaca - LCA"},
            {value:"HFA", label:"Haifa - HFA"},
        ],

        "dateFormat":"DD/MM/YYYY",
        "logo":"/assets/images/logo-large.png",
        "logoSmall":"/assets/images/logoSmall.png",
        "rightImage":"/assets/images/footer-tree.png",
        "boardBasis":[
            "full_board",
            "sleep_only",
            "breakfast_kidush",
            "all_include",
            "breakfast",
            "half_board",
            "half_board_kosher",
        ],
        "hotelStar":[
            '3', '4', '5'
        ],
        "destinations": {
            "local": [],
            "abroad":[
                // {
                //     "label": "טאבה הייטס - סיני ",
                //     "value": 3419
                // },
                {
                    "label": "מאמאיה - רומניה",
                    "value": 3423
                },
                {
                    "label": "קפריסין הצפונית",
                    "value": 3396,
                },
                // {
                //     "label": "בוקרשט - רומניה",
                //     "value": 3421
                // },
                {
                    "label": "ורנה - בולגריה",
                    "value": 3437
                },
                // {
                //     "label": "לימסול - קפריסין",
                //     "value": 3425
                // },
                // {
                //     "label": "איה נאפה - קפריסין",
                //     "value": 3422
                // },
                // {
                //     "label": "פרוטראס - קפריסין",
                //     "value": 3428
                // },
                // {
                //     "label": "אתונה - יוון ",
                //     "value": 3400
                // },
                // {
                //     "label": "לוטרקי - יוון",
                //     "value": 3418
                // },
                // {
                //     "label": "עקבה - ירדן ",
                //     "value": 3424
                // }
            ]
        },
        "occupancy": [
            { adult: 2, child: 0},
            { adult: 3, child: 0},
            { adult: 2, child: 1},
            { adult: 2, child: 2},
        ],
        "email": {
            "value": "info@sky-group.co.il",
            "title": "זמינים במייל",
        },
        "phone": {
            "value": "077-772-9055",
            "title": "התקשרו או כתבו לנו בוואטסאפ",
            "hours": "שעות פעילות 09:00-17:00"
        },
        "facebook":{
            "value":"https://www.facebook.com/familybreak.co.il/",
            "title":"פרגנו לנו בעוקב בפייסבוק"
        },
        "instagram":{
            "value":"https://www.instagram.com/familybreak.co.il/",
            "title":"פרגנו לנו בעוקב באינסטגרם"
        },
        "whatsapp": {
            "phone":"077-772-9055",
            // "phone":"053-595-2822",
            "message":"",
        },
        "companyInfo": {
            "title":"Familybreak",
            "value":"מעניקה שירותי קרקע ומענה לקהל הישראלי ביעדי אירופה עם מגוון אטרקציות למשפחות,זוגות וצעירים",
            "address": "ויצמן 24, נתניה"
        }
    },
    "theme": {
        "primary": "#00CCFF",
        "secondary": "rgba(79,205,234,0.86)",
        // "secondary": "#00CCFF",
        // "secondary": "#FFD62D",
        "tertiary": "#c2c2c2",
        "background": "#fff",
        "button": "btn-style-5",
        "fontFamily": "Heebo",
        "title": "title-style-2"
    },
    "layouts": {
        "default": {
            "topModules": [
                "Header",
            ],
            "bottomModules": [
                "Footer",
            ]
        },
        "noFooter": {
            "topModules": [

            ],
            "bottomModules": [
                "Footer"
            ]
        },
        "empty": {
            "topModules": [

            ],
            "bottomModules": [

            ]
        }
    },
    "pages": [
        {
            "path": "/",
            "type":"index_page",
            "modules": [
                "SearchDatePickerMainPage",
                "DestinationListLight",
                "PackageListViewFilters",
                "BannerLargeGirl",
                "PackageListView",
                // "BannerLarge",
                // "PackageListViewNextWeek",
                "Socials"
            ],
            "name": "Main Page",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": ["/prom/:promSecret/package/:id",'/package/view','/package/:id', "/p/:alias","/event/:alias/","/event/:alias/:affiliate","/package/:id/:affiliate","/prom/:promSecret/package/:id/:affiliate"],
            "modules": [
                "PackagePage",
                "Activities"
            ],
            "name": "Package Info",
            "type": "package_page",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": ["/prom/:promSecret", "/prom/:promSecret/:affiliate"],
            "modules": [
                "PromoPage",
            ],
            "name": "Main Page",
            "type": "index_page",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/room/:id",
            "modules": [
                "ImgCarousel",
                "SearchDatePicker",
                "RoomInfo",
                "OrderNow",
                "Activities",
                "RoomGeneralDetails"
            ],
            "name": "Room Info",
            "type": "room_page",
            "layout": "empty",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/packages",
            "modules": [
                "SearchDatePickerSearchPackagePage"
            ],
            "name": "Packages Search",
            "type": "searchResultPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/flights",
            "modules": [
                "SearchDatePickerFlightPage"
            ],
            "name": "Packages Search",
            "type": "search_page",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/hotels",
            "modules": [
                "SearchDatePickerHotelPage"
            ],
            "name": "Packages Search",
            "type": "searchResultPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        // {
        //     "path": "/hotels/:hotelId",
        //     "modules": [
        //         "PackageDetails",
        //         "PackageRoomPricing",
        //     ],
        //     "name": "Package Info",
        //     "type": "HotelPage",
        //     "layout": "default",
        //     "seo": {
        //         "title": "",
        //         "desc": ""
        //     }
        // },
        {
            "path": "/checkout/:id",
            "modules": [
                "Checkout",
            ],
            "name": "Checkout",
            "type": "checkout_page",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/order/manage",
            "modules": [
                "ManageOrder",
            ],
            "name": "Manage Order",
            "type": "manage_page",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/contact",
            "modules": [
                "SearchDatePickerMainPage",
                "ContactUs",
            ],
            "name": "ContactUs",
            "type": "contact_us_page",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/about",
            "modules": [
                "SearchDatePickerMainPage",
                "PageContentAboutUs",
            ],
            "name": "AboutUs",
            "type": "index_page",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/takanon",
            "modules": [
                "SearchDatePickerMainPage",
                "PageContentTakanon",
            ],
            "name": "Takanon",
            "type": "index_page",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/passenger-info",
            "modules": [
                "SearchDatePickerMainPage",
                "PageContentPassenger",
            ],
            "name": "PassengerInfo",
            "type": "index_page",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/hotel/:id/:name",
            "modules": [
                "HotelPage"
            ],
            "name": "Hotel Dynamic Page",
            "type": "hotel_page",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            },

        },
        {
            "path": "/destinations",
            "modules": [
                "SearchDatePickerMainPage",
                "DestinationList",
            ],
            "name": "Destination Page",
            "type": "index_page",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path":"/flight/view/:type/:flightId",
            "modules": [
                "SearchDatePickerFlightViewPage",
                "ViewFlight",
            ],
            "name": "flightIndexPage",
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/404",
            "modules": [
                "NotFound404",
                // "SearchDatePickerMainPage",
            ],
            "name": "NotFound404",
            "type": "index_page",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
    ],
    "modulesConfigs": {
        "Header": {
            "links": [
                {
                    "text": "עמוד הבית",
                    "url": "/"
                },
                {
                    "text": "חבילות נופש",
                    "url": "/packages"
                },
                {
                    "text": "מי אנחנו",
                    "url": "/about"
                },
                // {
                //     "text": "על הצימר",
                //     "url": "/bandb"
                // },
                // {
                //     "text": "החדרים",
                //     "url": "/rooms"
                // },
                // {
                //     "text": "אטרקציות",
                //     "url": "/attractions"
                // },
                {
                    "text": "צור קשר",
                    "url": "/contact"
                }
            ],
            "theme": {
                "tertiary": "black",
                "primary": "#00CCFF",
                "background": "#fff"
            },
            "set": "HeaderSet2",
            "logo": "/assets/images/logo-large.png",
            "widgetLangs": {
                "isActive": true,
            },
            "widgetSocial": {
                "isActive": true,
                "socials": [{
                    "key": "instagram",
                    "value": "https://www.instagram.com/familybreak.co.il/",
                }, {
                    "key": "facebook",
                    "value": "https://www.facebook.com/familybreak.co.il/"
                }, {
                    "key": "whatsapp",
                    // "value": "whatsapp://send?text=ברוכים הבאים למענה של פמילי ברייק!&phone=+972535952822"
                    "value": "whatsapp://send?text=ברוכים הבאים למענה של פמיליברייק!&phone=+972777729055"
                }
                ],
            },
            "module": "Header"
        },
        "Footer": {
            "columns": [
                {
                    "header": "צור קשר",
                    "links": [
                        {
                            "text": "כתבו לנו",
                            "url": "/contact"
                        },
                        {
                            "text": "מי אנחנו?",
                            "url": "/about"
                        },
                        {
                            "text": "תקנון האתר",
                            "url": "/takanon"
                        },
                        {
                            "text": "מידע כללי לנוסע",
                            "url": "/passenger-info"
                        }
                    ]
                },
                // {
                //     "header": "אטרקציות",
                //     "links": [
                //         {
                //             "text": "ספא",
                //             "url": "/"
                //         },
                //         {
                //             "text": "מסעדות",
                //             "url": "/"
                //         },
                //         {
                //             "text": "ספא משפחתי",
                //             "url": "/"
                //         },
                //         {
                //             "text": "לראות את הצפון",
                //             "url": "/"
                //         },
                //         {
                //             "text": "טיול קניות",
                //             "url": "/"
                //         },
                //         {
                //             "text": "טיול טבע",
                //             "url": "/"
                //         },
                //         {
                //             "text": "טיול למדבר",
                //             "url": "/"
                //         }
                //     ]
                // },
                // {
                //     "header": "החדרים",
                //     "links": [
                //         {
                //             "text": "חדר סטנדטנ",
                //             "url": "/"
                //         },
                //         {
                //             "text": "חדר אקזקיוטיב",
                //             "url": "/"
                //         },
                //         {
                //             "text": "חדר משפחה",
                //             "url": "/"
                //         },
                //         {
                //             "text": "חדר סוויטה",
                //             "url": "/"
                //         }
                //     ]
                // }
            ],
            "set": "FooterSet2",
            "module": "Footer",
            "widgetScrollToTop": {
                "isActive": true
            },
            "logo": "/assets/images/logo-large.png",
            "theme": {
                "background": "#00CCFF",
                "tertiary": "#000"
            }
        },
        "SearchDatePickerMainPage": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1",
                "primary": "rgba(0,204,255,0.65)",
                "secondary": "#FFD62D",
            },
            "set": "SearchDatePickerSet2",
            "showSearchResults":{
                "isActive":false
            },
            'searchOptions':['hotel', 'package', 'flights'],
            "backgroundImg": "/assets/images/familybreak/back-main2.jpg",
            "widgetDatePickerCarousel": {
                "isActive": false
            },
            "widgetVacationTypes": {
                "isActive": false
            },
        },
        "DestinationListLight":{
            "set": 'DestinationListLightSet1',
            "linkType": 'packageSearch',
            "title": 'היעדים שלנו',
        },
        "DestinationList":{
            "set": 'DestinationListSet1',
            "linkType": 'packageSearch',
            "title": 'היעדים שלנו',
        },
        "BannerLarge": {
            "module": "BannerLarge",
            "img": "/assets/images/famflight.png",
            "set": "BannerSet2",
            "button": false,
            "contentWidth": true,
            "backSize": "contain",
        },
        "BannerLargeGirl": {
            "module": "BannerLarge",
            "key": "BannerLargeGirl",
            "set": "BannerSet1",
            "img": "/assets/images/baby-girl.png",
            // "link": "https://www.familybreak.co.il/prom/משפחות_מאמאיה",
            "button": false,
            "textLine1":"מאמאיה מחכה לכם!",
            "theme":{
                background:"#FFD62D"
            }
        },
        "ImgCarousel": {
            "carousel": [
                {
                    "img": "https://www.africanbusinessreview.co.za/wp-content/uploads/2021/06/take-a-vacation.jpeg",
                    "text": "צימר רמת הגולן המקום המושלם."
                },
                {
                    "img": "https://cdn2.hubspot.net/hubfs/439788/Blog/Featured%20Images/Best%20Hotel%20Website%20Designs.jpg",
                    "text": "צימר רמת הגולן המקום המושלם."
                }
            ],
            "theme": {
                "title": "title-style-1"
            },
            "module": "ImgCarousel"
        },
        "DatesSuggestion": {
            "buttonText": "לעוד דילים טובים ›",
            "url": "#",
            "title": "תאריכי חופשה",
            "titleLineTwo": "פופולאריים",
            "theme": {
                "title": "title-style-2"
            },
            "module": "DatesSuggestion"
        },
        "Description": {
            "img": "https://www.africanbusinessreview.co.za/wp-content/uploads/2021/06/take-a-vacation.jpeg",
            "description": "המקום המושלם לחופש, שקט והנאה למשפחות.",
            "theme": {
                "title": "title-style-2"
            },
            "module": "Description"
        },
        "ContactUs": {
            "img": "https://www.africanbusinessreview.co.za/wp-content/uploads/2021/06/take-a-vacation.jpeg",
            "set":"ContactUsSet2",
            "title":"צרו איתנו קשר.",
            "titleLineTwo": "צרו איתנו קשר.  נשמח לעזור לכם להזמין חופשה מושלמת.",

            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "ContactUs"
        },
        "PageContentAboutUs": {
            "title":"צרו איתנו קשר.",
            "set":"PageContentSet1",
            "desc_id": 3,
            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "PageContent"
        },
        "PageContentTakanon": {
            "title":"תקנון האתר",
            "set":"PageContentSet1",
            "desc_id": 1,
            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "PageContent"
        },
        "PageContentPassenger": {
            "title":"מידע לנוסע",
            "set":"PageContentSet1",
            "desc_id": 2,
            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "PageContent"
        },
        'SearchDatePickerWithSwitcher':{
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1",
                "background": "#00ccff33"
            },
            'searchOptions':[ 'packages', 'flights', "hotels"],
            "set": "SearchDatePicketSwitcher",
            'searchResultType' : "packages",

            "autoSearch":false,
            "showSearchResults":{
                isActive:false
            },
            "widgetDatePickerCarousel": {
                isActive: false
            },
            "widgetVacationTypes": {
                isActive: false
            },
        },
        'SearchDatePickerFlightViewPage':{
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1",
                "background": "#00ccff33"
            },
            'searchOptions':[],
            "set": "SearchDatePicketSwitcher",
            'searchResultType' : "flights",
            "autoSearch":false,
            "showSearchResults":{
                isActive:false
            },
            "widgetDatePickerCarousel": {
                isActive: false
            },
            "widgetVacationTypes": {
                isActive: false
            },
        },
        "SearchDatePickerSearchPackagePage": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1",
                "background": "#00ccff33",
                "secondary": "#FFD62D",
            },
            "searchResultType": "packages",
            "resultConfig": {
                "boxSet": "packageBoxWithGallery",
                "theme": {
                    "background": "#00ccff33",
                    "secondary": "#00ccff",
                }
            },
            'searchOptions': [],
            "set": "SearchDatePickerPackageIndex",
            "setConfig": {
                "promotionText": "בחרו מספר יעדים  בין תאריכים ונמצא לכם את החופשה המושלמת!",
            },
            "showSearchResults":{
                isActive:true
            },
            "widgetDatePickerCarousel": {
                isActive: false
            },
            "widgetVacationTypes": {
                isActive: false
            },
            "backgroundImg":"/assets/images/airplane.png",
        },
        "SearchDatePickerFlightPage": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1",
                "background": "#00ccff33"
            },
            'searchResultType' : "flights",
            'searchOptions': [],
            'autoSearch':true,
            "set": "SearchDatePicketSwitcher",
            "setConfig": {
                "promotionText": "בחרו מספר יעדים  בין תאריכים ונמצא לכם את החופשה המושלמת!",
            },
            "showSearchResults":{
                isActive:true
            },
            "widgetDatePickerCarousel": {
                isActive: false
            },
            "flightSummary":{
                isActive: true
            },
            "widgetVacationTypes": {
                isActive: false
            },
        },
        "HotelPage": {
            "PackageDetailsConfig": {
                "widgetFlights": {
                    "isActive": false,
                    "widgetSet": 'FlightsSet2',
                },
                widgetHotelInfo: {
                    "isActive": true,
                    "widgetSet": 'HotelInfoSet2',
                },
                widgetActivities: {
                    "isActive": true,
                    "widgetSet": 'ActivitiesSet2',
                },
                showDates: false,
            },
            "RoomPricingConfig" : {
                "set": 'RoomPricingSet2',
                "theme": {
                    "button": "btn-style-5",
                    "tertiary": "black",
                    "background": "#00ccff33"
                },
            }
        },
        "SearchDatePickerHotelPage": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1",
                "background": "#00ccff33"
            },
            'searchOptions':[],
            "set": "SearchDatePicketSwitcher",
            'searchResultType' : "hotels",
            "setConfig": {
                "promotionText": "בחרו מספר יעדים  בין תאריכים ונמצא לכם את החופשה המושלמת!",
            },
            "showSearchResults":{
                isActive:true
            },
            "widgetDatePickerCarousel": {
                isActive: false
            },
            "flightSummary":{
                isActive: true
            },
            "widgetVacationTypes": {
                isActive: false
            },
        },
        "NotFound404":{
            "image":"/assets/images/404.png",
            "title":'אופס.. הדף שחיפשת לא קיים',
            "redirect_title":'הנך מועבר לדף הבית',
            "redirect_seconds":7000,
        },
        "RoomInfo": {
            "theme": {
                "title": "title-style-1"
            },
            "set": "RoomInfoSet1",
            "widgetRoomDetails": {
                "isActive": true
            },
            "widgetRoomFacilities": {
                "isActive": true
            },
            "widgetRoomPictures": {
                "isActive": true
            },
            "facilities": [
                "TV",
                "Telephone",
                "Card",
                "Bed",
                "Radio",
                "BreakFast",
                "Porter",
                "FiveStar",
                "Juice",
                "HouseKeeping",
                "DoNotDisturb",
                "Hostess"
            ],
            "module": "RoomInfo"
        },
        "OrderNow": {
            "theme": {
                "background": "#42595d"
            },
            "module": "OrderNow"
        },
        "ActivitiesSuggestion": {
            "buttonText": "לכל האטרקציות ›",
            "url": "#",
            "title": "אטרקציות וחוויות",
            "titleLineTwo": "בקרבת מקום",
            "theme": {
                "title": "title-style-3"
            },
            "module": "ActivitiesSuggestion"
        },
        "SearchDatePicker": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1"
            },
            "set": "SearchDatePickerSmallPackage",
            "showSearchResults":{
                "isActive":true
            },
            "widgetDatePickerCarousel": {
                "isActive": false
            },
            "widgetVacationTypes": {
                "isActive": false
            },
        },
        "PromoPage": {
            "module": "PromoPage",
            "packageListConfig": {
                "set": "PackageListViewSet2",
                "backgroundType":"type1",
                "widgetPackageFilter": {
                    "isActive": true,
                    'filterBy': ['date']
                },
                "widgetFilterMenu": {
                    "isActive": false,
                },
                "widgetMorePackages": {
                    "isActive": false
                },
                "widgetPackageBoxHeader": {
                    "isActive": true,
                    "title": ""
                }
            },
        },
        "PackageListView": {
            "set": "PackageListViewSet2",
            "boxSet": "packageBoxWithGallery",
            "theme": {
                "background": "#00ccff33",
                "secondary": "#00ccff",
            },
            "module": "PackageListView",
            "backgroundType":"type1",
            "defaultFilters":{
                "staticFilter": "type=indexList",
                "limit":3,
                "dates": {
                    "from": 1,
                    "range": 14
                }
            },
            "widgetFilterMenu": {
                "isActive": false,
            },
            "widgetMorePackages": {
                "isActive": false
            },
            "widgetPackageBoxHeader": {
                "isActive": true,
                "title":"חבילות הנופש הנצפות ביותר"
            }
        },
        "PackageListViewFilters": {
            set: "PackageListViewSet2",
            "module": "PackageListView",
            "theme": {
                "button": "btn-style-5",
            },
            "backgroundType":false,
            "widgetFilterMenu": {
                "isActive": true,
                "listFilters": [
                    {
                        "name": "מאמיה - אוגוסט",
                        "filters": {
                            "dates": "25/07/2024-01/09/2024",
                            "limit": "6",
                            "destination":"3423"
                        }
                    },
                    {
                        "name": "קפריסין הצפונית",
                        "filters": {
                            "dates": "25/07/2024-01/09/2024",
                            "limit": "6",
                            "destination":"3396"
                        }
                    },
                    // {
                    //     "name": "טיסות בלבד - לרנקה",
                    //     "filters": {
                    //         "dates": "25/07/2024-01/09/2024",
                    //         "limit": "6",
                    //         "destination":"3396"
                    //     }
                    // },
                    // {
                    //     "name": "מחירים מיוחדים יולי",
                    //     "filters": {
                    //         "source": "alp",
                    //         "feature": "cruise",
                    //         "dates": "01/07/2023-01/08/2023",
                    //         "limit": "12",
                    //     }
                    // },
                    // {
                    //     "name": "חופשה עם הילדים",
                    //     "filters": {
                    //         "dates": "01/08/2023-01/09/2023",
                    //         "limit": "12",
                    //     }
                    // }
                ],
                "title":"חבילות נופש לכל המשפחה",
                "titleLineTwo":"ביעדים והמלונות שלנו!"
            },
            "widgetMorePackages": {
                "isActive": true
            },
            "widgetPackageBoxHeader": {
                "isActive": false
            }

        },
        "PackageListViewNextWeek": {
            "set": "PackageListViewSet2",
            "module": "PackageListView",
            "boxSet": "packageBoxWithGallery",
            "theme": {
                // "background": "rgba(0,255,72,0.2)",
                // "primary": "#60c045",
                // "secondary": "#8ed97b",
                "background": "#00ccff33",
                "secondary": "#00ccff",
            },
            "backgroundType":false,
            "defaultFilters":{
                "staticFilter": "type=upcoming",
                "limit":3,
                "dates": {
                    "from": 7,
                    "range": 21
                }
            },
            "widgetFilterMenu": {
                "isActive": false,
            },
            "widgetMorePackages": {
                "isActive": false
            },
            "widgetPackageBoxHeader": {
                "isActive": true,
                "title":"כמה אתם ספונטנים?"
            }
        },
        "PackageDetails": {
            "theme": {
                "button": "btn-style-5",
                "primary": "#00CCFF",
                "secondary": "#FFD62D",
                "tertiary": "#000000"
            },
            "widgetFlights": {
                "isActive": true,
                "widgetSet": "FlightsSet1",
            },
            "widgetHotelInfo": {
                "isActive": true,
                "widgetSet": "HotelInfoSet1",
            },
            "widgetActivities": {
                "isActive": true,
                "widgetSet": "ActivitiesSet1",
            }

        },
        "HotelDetails": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "black",
                "background": "#00ccff33"
            },
            "widgetHotelInfo": {
                "isActive": true,
                "widgetSet": "HotelInfoSet1",
            },
            "widgetDatePicker": {
                "isActive": true,
                "widgetSet": "HotelDatePickerSet1",
            },
        },
        "RoomPricing": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "black",
                "background": "#00ccff33"
            },
        },
        "HotelRoomPricing": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "black",
                "background": "#00ccff33"
            },
        },
        "PackagePage": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "black",
                "background": "#00ccff33"
            },
            "PackageDetailsConfig": {

                "widgetFlights": {
                    "isActive": true,
                    "widgetSet": 'FlightsSet2',
                },
                widgetHotelInfo: {
                    "isActive": true,
                    "widgetSet": 'HotelInfoSet1',
                },
                widgetActivities: {
                    "theme": {
                        "button": "btn-style-5",
                        "background": "#fff",
                        "tertiary": "#000000"
                    },
                    "isActive": true,
                    "widgetSet": 'ActivitiesSet2',
                },
                showDates: false,
            },
            "RoomPricingConfig": {
                "set": 'RoomPricingSet1',
                "theme": {
                    "button": "btn-style-5",
                    "tertiary": "black",
                    "background": "#00ccff33"
                },
            }
        },
        "Activities": {
            "theme": {
                "tertiary": "black"
            },
            "set": "ActivitiesSet1",
            "module": "Activities"
        },
        "ViewFlight":{
            "theme":{
                "tertiary": "#ccc",
                "background":"#E1F8FC"
            }
        }
    }
};
export default appConfigFamilybreak;