import React from 'react';
import { Button, ButtonProps } from 'antd';
import { IconType } from 'react-icons/lib';

interface Props extends ButtonProps {
  text: string;
  Icon?: IconType | React.FC;
  className?: string;
}

const ButtonWithIcon = ({ text, Icon, className, ...buttonProps }: Props) => {
  return (
    <Button
      {...buttonProps}
      className={`button-with-arrow ${className} ${
        buttonProps.disabled ? 'button-with-arrow-disabled' : ''
      }`}
    >
      {text}
      {Icon && <Icon />}
    </Button>
  );
};

export default ButtonWithIcon;
