const endpoints = {
    AGENT: {
        POST_INFO: "/supplier/site/agentInfo",
    },
    ORDER: {
        CREATE: "/b2c/order/create",
        UPDATE: "/b2c/order/update",
        GET: "/b2c/order/get",
        CHECK_DISCOUNT_CODE:"/b2c/order/check-code",
        UPLOAD_ATTACHMENT:"/b2c/order/UploadGuestAttachment",
        UPDATE_INFANTS:"/b2c/order/updateInfantCount",
    },
    PAYMENT: {
        FORM: "/b2c/payment/form",
        CUSTOM_DATA_UPDATE: "/b2c/payment/custom-data-update",
        TRANSACTION_STATUS: "/b2c/payment/transaction-status"
    },
    PROMOTION: {
        GET: "/b2c/package/promotion",
        VALIDATE_SUBSIDIZE:"b2c/group/validateEmployee"
    },
    SITE: {
        UINFO: "/b2c/site/uinfo",
        PAGE: "/b2c/site/page",
    },
    PACKAGE: {
        CANCELATION: "/b2c/destination/cancellationTerms",
        GET:'/b2c/package/get',
        GET_MS:'/ms/product-view/package', // Tamta
        FILTER:'/b2c/package/filter',
        SEARCH_MS:'/ms/product-search/package/search',
        SEARCH:'/b2c/package/filter',
        GET_PRICE:"/b2c/package/price"
    },
    CONCEPT:{
        INDEX:'/b2c/concept/index',
        PACKAGES:'/b2c/concept/packages',
        VIEW:"/b2c/concept/view"
    },
    ACTIVITIES: {
        GET : '/b2c/activity/get'
    },
    APP:{
        config:'/b2c/site/config',
        translations:"/b2c/site/tran"
    },
    HOTEL:{
        GET:"/b2c/hotel/price",
        GET_HOTEL: "/b2c/hotel/get",
        VIEW:"/b2c/hotel/view",
        PRICE:"/b2c/hotel/price",
        SEARCH: "/ms/product-search/hotel/search"
    },
    DESTINATIONS:{
        GET:"/b2c/destination/index"
    },
    FLIGHTS:{
        // SEARCH_ONE_WAY
        GET:"/ms/product-search/flight/single-search",
        // SEARCH_BUNDLE
        GET_BUNDLE:"/ms/product-search/flight/bundle-search",

        VIEW_FLIGHT_MS:"/ms/product-view/flight",
        VIEW_BUNDLE_MS:"/ms/product-view/flight",

        VIEW_FLIGHT:"/b2c/flight/get"
    },
    AUTOCOMPLETE:{
        CITY:"/ms/product-search/autocomplete/city",
        AIRPORT:"/ms/product-search/autocomplete/airports",
        GET_AIRPORT:"/ms/product-search/airport",
        GET_CITY:"/ms/product-search/city",
    }
};

export default endpoints;
