const translationsEn = {
    "status": "success",
    "data": {
        "lang": "en",
        "app": "hotel",
        "translation": {
            "crud": {
                "validationFailed" : "Перевірка не пройшла",
                "operationError" : "Виникла помилка",
                "emptyList" : "Дані не знайдено",
                "failToLoad" : "Помилка завантаження",
                "modelNotFound" : "Не знайдено",
            },
            "contactUs": {
                "email": "Електронна пошта",
                "phone": "Телефон",
                "fullName": "Повне ім'я",
                "writeUsMessage": "Напишіть нам повідомлення",
                "send": "Відправити",
                "phoneRequired": "Будь ласка, введіть номер телефону",
                "emailRequired": "Будь ласка, введіть адресу електронної пошти",
                "fullNameRequired": "Будь ласка, введіть повне ім'я",
                "messageRequired": "Будь ласка, введіть повідомлення",
                "aboutUs": "Про нас"
            },
            "cart": {
                "addToCart": "Додати до кошика",
            },
            "roomInfo": {
                "info": "Опис номера",
                "viewAllThePhotos": "Переглянути всі фотографії",
                "roomSize": "Розмір номера (кв. м)",
                "peopleInTheRoom": "Люди в номері",
                "doubleBed": "Двоспальне ліжко"
            },
            "room": {
                "roomSize": "Розмір номера",
                "bedType": "Тип ліжка",
                "breakfastIncluded": "Сніданок включено",
                "noAvailabilityOnSelectedDates": "Немає вільних номерів на обрані дати"
            },
            "promotion": {
                "modelNotFound": "Сторінку не знайдено",
                "failToLoad": "Не вдалося завантажити сторінку!"
            },
            "searchDatePicker": {
                "search": "Пошук",
                "rooms": "Номери",
                "composition": "Склад",
                "selectDate": "Select Date",
                "select_package_dates": "Select Package Dates",
                "chooseComposition": "Виберіть склад",
                "numberOfGuests": "Кількість гостей",
                "checkOut": "Виїзд",
                "checkIn": "Заселення",
                "whatVacation": "Який вид відпустки",
                "youWant": "ви хочете",
                "vacationFriendsWith": "Відпустка з друзями",
                "familyVacation": "Сімейна відпустка",
                "romanticVacation": "Романтична відпустка",
                "notAvailable": "Недоступно",
                "roomAvailableDuringTheWeek": "Доступність номерів протягом тижня",
                "followingDates": "Наступні дати",
                "vacation": "Відпустка",
                "abroad": "За кордоном",
                "inIsrael": "В країні",
                "destination": "Усі напрямки",
                "chooseDestination": "Виберіть напрямки",
                "children": "Діти",
                "adults": "Дорослі",
                "findDreamVacation": "Знайдіть свою ідеальну відпустку одним кліком.",
                "searchResTitle": "Виберіть правильний пакет для вас"
            },
            "specialDeal": {
                "closeTheDeal": "Закрийте угоду ›",
                "endsIn": "Закінчується через",
                "specialDealForTheWeekend": "Спеціальна пропозиція на вихідні",
                "from": "З дати",
                "until": "До дати"
            },
            "footer": {
                "backToTop": "Повернутися до верху",
                "allRightsReserved": "© Усі права захищені. Не копіюйте вміст сайту без дозволу.",
                "developBy": "Розроблено SKY 360 Tech.",
                "thePurchaseOnTheWebsiteIsSecure": "Покупка на сайті є безпечною.",
                "talkToUs": "Як з нами спілкуватися?",
                "address": "Адреса",
                "phoneAndWhatsapp": "Телефон або WhatsApp",
                "phone": "Телефон",
                "whatsapp": "Whatsapp",
            },
            "packages": {
                "from": "З дати",
                "until": "До дати",
                "breakfastIncluded": "Сніданок включено",
                "includesMassageAndSpa": "Включає масаж і SPA!",
                "lastRoom": "Останній номер!",
                "includesTransfers": "Включає трансфери",
                "includesActivities": "Включає визначні місця",
                "nights": "Ночей",
                "withoutBaggage": "Без багажу",
                "directFlight": "Прямий рейс",
                "oneStop":"Один пересадочний пункт",
                "numOfStops":"Зупинки",
                "flightDetails": "Деталі політів",
                "flightFrom": " from -> to",
                "flightBack": " from -> to",
                "hours": "Години",
                "flightNumber": "Номер рейсу",
                "airLine": "Авіакомпанія",
                "suitcaseWeight": "weightкг Багаж включено",
                "trollyWeight": "weightкг Багажна тележка включена",
                "includedActivitiesTitle": "Атракції та екскурсії в пакеті",
                "checkAllPictures": "Переглянути всі фотографії",
                "affiliateWith": "У співпраці з",
                "addButtonDisabledTooltip": "Замовлення обмежено кількістю номерів - не можна додати інший номер. Щоб змінити номер, видаліть номер з кошика",
                "holidaySchedule": "Графік відпусток",
                "passengers":"Пасажири"
            },
            "roomPricing": {
                "addPassenger": "Додати пасажира",
                "pricePerPerson": "Ціна за особу",
                "pricePerInfant": "Ціна за немовля",
                "composition": "Склад",
                "adultsInTheRoom": "Дорослі",
                "children": "Діти",
                "adults": "Дорослі",
                "single": "Один дорослий",
                "child": "Дитина",
                "selectInfantCount": "Виберіть зі списку",
                "infant": "Немовля",
                "1infant": "Одне немовля",
                "2infants": "2 немовля",
                "infants": "Немовлята",
                "noInfants": "Без немовлят",
                "all_include": "Все включено",
                "breakfast": "Сніданок",
                "activities": "Виберіть тур",
                "half_board": "Півпансіон",
                "half_board_kosher": "Кошерний півпансіон",
                "full_board": "Повний пансіон",
                "sleep_only": "Тільки сон",
                "breakfast_kidush": "П'ятниця ранок та вечірній обід",
                "addToCard": "Додати до замовлення",
                "inPackageDates": "В дати пакету",
                "chooseRoom": "Виберіть склад і номер",
                "nights": "Ночей",
                "boardType": "Тип харчування",
                "filters": "Фільтри",
                "noAvailableRooms": "На обрані дати немає доступних номерів",
                "noAvailableRoomsForDates": "Номер не доступний на обрані дати",
                "addInfant": "Додати немовля"
            },
            "activitiesTranslations": {
                "addToOrder": "Додати до замовлення",
                "passenger": "Для пасажира",
                "showMore": "Показати більше",
                "addAttractionsAndTrips": "Додати атракції та екскурсії"
            },
            "modal": {
                "viewAllImages": "Переглянути всі зображення",
                "galleryTitle": "Галерея фотографій",
                "cancelationTerms": "Умови скасування - ",
                "nameOfTheHotel": "Деталі готелю",
                "hotelInfo": "Про готель",
                "hotelWebsite": "Посилання на веб-сайт готелю"
            },
            "shoppingCartTranslations": {
                "shoppingCartButtonTooltip": "Виберіть склад і додайте до замовлення",
                "totalPrice": "Загальна сума",
                "proceedToPayment": "Перейти до оплати",
                "placeOrder": "Перейти до реєстрації",
                "NoHotelWasChosenForTheVacation": "Готель не було обрано для відпустки",
                "betweenDates": "Між датами",
                "packageInformation": "Деталі пакета",
                "rooms": "Деталі номерів",
                "transfers": "Трансфери",
                "reg_transfers": "Трансфери/шатли до та з готелю",
                "shuttle_transfers": "Шатл з кордону до готелю і назад",
                "flights": "Рейси",
                "activities": "Атракції",
                "shoppingCart": "Кошик",
                "package": "Ночей в готелі",
                "nights": "Ночей",
                "inHotel": "В готелі",
                "apply": "Підтвердити",
                "promoCode": "Промокод",
                "yourShoppingCart": "Ваш кошик",
                "isEmpty": "Досі порожній...",
                "myVacation": "Моя відпустка",
                "showAll": "Показати все »",
                "beforeDiscount": "Загальна сума перед знижкою -",
                "agentCommission": "Агентська комісія -",
                "agentCommissionNet": "Агентська комісія (чистий платіж) -",
                "discountFromCode": "Знижка за кодом -",
                "affiliateWith": "У співпраці з -",
                "tpId": "ID замовлення -",
                "chargeTypeFullCC": "Повна оплата - включаючи комісію",
                "chargeTypeNetCC": "Оплата за чисту вартість",
                "chargeTypeCash": "Готівкова операція",
                "agentCommissionPrec": "Відсоток агентської комісії",
                "priceOff": "Знижка",
                "percentageOff": "Відсоток знижки",
                "selectedItems": "Вибрані товари"
            },
            "checkout": {
                "room": "Номер",
                "personalInfo": "Дані відпочиваючих",
                "paymentDetails": "Деталі платежу",
                "orderCompletion": "Завершення замовлення",
                "userDetails": "Деталі замовника",
                "name": "Ім'я",
                "lastName": "Прізвище",
                "passport": "Паспорт",
                "inEnlgish": "",
                "birthdate": "Дата народження",
                "phone": "Телефон",
                "email": "Електронна пошта",
                "notesForTheOrder": "Примітки до замовлення",
                "namesOfGuests": "Імена пасажирів",
                "namesMustMatchPassport": "* Усі імена повинні бути введені англійською мовою точно так, як вони вказані в паспорті",
                "goToPayments": "Підтвердити та перейти до оплати",
                "saveOrder": "Зберегти замовлення",
                "agreement": "Я підтверджую умови веб-сайту, умови замовлення та умови скасування замовлення",
                "men": "Чоловік",
                "women": "Жінка",
                "pleaseInputYourPhone": "Будь ласка, введіть номер телефону",
                "pleaseInputYourLastName": "Будь ласка, введіть ваше прізвище",
                "pleaseInputYourFirstName": "Будь ласка, введіть ваше ім'я",
                "pleaseInputYourEmail": "Будь ласка, введіть вашу електронну пошту",
                "pleaseInputYourPassportNumber": "Будь ласка, введіть номер вашого паспорта",
                "pleaseInputYourDateOfBirth": "Будь ласка, введіть вашу дату народження",
                "pleaseSelectGender": "Будь ласка, виберіть стать",
                "maxChildAge": "Дитина віком від 2 років -",
                "maxInfantAge": "Немовля повинно бути менше двох років на момент відпустки",
                "pleaseAgreeWithT&C": "Будь ласка, погодьтеся з умовами використання та правилами веб-сайту",
                "viewTerms": "Переглянути правила",
                "invalidEmail": "Недійсна електронна пошта",
                "orderCreateSuccess": "Ваше замовлення успішно збережено, вас перенаправляють до оплати",
                "orderCreateFree": "Ваше замовлення успішно збережено!",
                "orderUpdateSuccess": "Ваше замовлення успішно оновлено!",
                "mainRoomGuestTitle": "Номер на ім'я",
                "uploadPassportImage": "Завантажити фотографію паспорта",
                "addInfant": "Додати немовля",
                "updateInfant": "Оновити кількість немовлят",
                "howManyInfantsDoYouWantToAdd": "Виберіть кількість немовлят для цього номера",
                "add": "Додати",
                "setInfantCountForRoom": "Оновити кількість немовлят",
                "setInfantCountForRoomSuccess": "Кількість немовлят успішно оновлено",
                "infantsAddedToTheRoom": "Немовлята додані до номера",
                "selectNumberOfInfants": "Виберіть кількість немовлят",
                "pricePerInfant": "Ціна за немовля",
                "infantsRemoved": "Немовлята були видалені з цього номера",
                "requiredUploadPassportImage": "Завантаження фотографії паспорта обов'язкове",
                "infantError4403": "Неможливо змінити кількість немовлят - будь ласка, зв'яжіться з центром обслуговування",
                "docketId": "* ID документа",
                "agencyId": "* ID агентства"
            },
            "manageOrder": {
                "moveToPayment": "Додати спосіб оплати",
                "backToManage": "Повернутися до управління замовленням",
                "paymentTitle": "Додати спосіб оплати",
                "manageTitle": "Оновлення даних відпочиваючих",
                "modelNotFound": "Замовлення не знайдено",
                "orderId": "Управління замовленням"
            },
            "completionForm": {
                "paymentFinish": {
                    "title": "Замовлення успішно зроблене - оплата завершена",
                    "info": "На вашу адресу відправлено електронний лист із деталями замовлення"
                },
                "waitingToApprove": {
                    "title": "Ваше замовлення надіслано на схвалення",
                    "info1": "Деталі замовлення збережено в системі та надіслано на схвалення.",
                    "info2": "Оплата буде здійснена лише після схвалення замовлення.",
                    "info3": "Після схвалення замовлення вас повідомлять електронною поштою про підтвердження та оплату."
                },
                "paymentFail": {
                    "title": "Оплата не вдалася",
                    "info1": "Оплата не вдалася :(",
                    "infoFail": "Після декількох спроб оплати вона не вдалася - спробуйте ще раз",
                    "infoWhatsapp": "Якщо виникла помилка та ви не можете оплатити, зв'яжіться через WhatsApp за адресою: "
                },
                "orderNum": "Номер замовлення",
                "thanksForChoose": "Дякуємо за вибір",
                "free": {
                    "title": "Реєстрація пройшла успішно",
                    "info": "На вашу адресу відправлено електронний лист із підсумками замовлення"
                },
                "pay_later": {
                    "title": "Реєстрація пройшла успішно",
                    "info": "На вашу адресу відправлено електронний лист із підтвердженням замовлення"
                }
            },
            "paymentForm": {
                "creditNotSaveInBd": "Дані кредитної картки не зберігаються на сайті - ви робите безпечний платіж",
                "title": "Введіть спосіб оплати (лише Visa і Mastercard)",
                "splitBtn": "Клацніть, щоб розділити кредитні карти",
                "splitTitle": "Розділити кредитні карти",
                "splitDesc": "Виберіть кількість кредитних карток для розділення та клацніть «Підтвердити»",
                "noSplit": "Без поділу",
                "noPayments": "Без оплати",
                "payments": "Оплати",
                "paymentsNum": "Кількість платежів",
                "withDebit": "З кредитом",
                "splitBy": "Кредитні карти",
                "toPay": "До оплати",
                "splitApprove": "Підтвердити",
                "currencyRate": "Сплата здійснюється в шекелях - за туристичним курсом",
                "splitCurrentCardNum": "Розділити кредит",
                "splitCurrentCardNumFrom": "З",
                "errorMessageToRetry": "Спроба № {retryNum} - не вдалося здійснити платіж від компанії, що видає кредитну картку. Введіть дані картки ще раз або іншу картку. Веб-сайт приймає лише картки VISA та MASTERCARD.",
            },
            "hotelDetails": {
                "updateDates": "Оновити дати",
                "roomTypesToChooseFrom": "Виберіть типи номерів"
            },
            "general": {
                "noResults": "Немає результатів",
                "nights": "Ночей",
                "includesActivities": "{NUMBER} подарункових атракцій!",
                "includesActivity": "Включає подарунковий атракціон!",
                "adults": "Дорослі",
                "all_include": "Все включено",
                "breakfast": "Сніданок",
                "half_board": "Півпансіон",
                "half_board_kosher": "Кошерний півпансіон",
                "full_board": "Повний пансіон",
                "sleep_only": "Тільки проживання",
                "breakfast_kidush": "П'ятниця сніданок та обід",
                "addToCard": "Додати до замовлення",
                "chooseRoom": "Виберіть склад і номер",
                "boardType": "Тип розміщення",
                "filters": "Фільтри",
                "rating": "Рейтинг готелю",
                "children": "Діти",
                "toDate": "До",
                "transfers": "Трансфери",
                "from": "Починаючи з",
                "perPerson": "За особу",
                "include": "Включає",
                "noIncludeText": "Пакети відпусток",
                "flights": "Рейси",
                "vacationAt": "Відпустка в {DEST}",
                "fieldRequired": "Обов'язкове поле"
            },
            "subsidizedForm": {
                "title": "Додати субсидію",
                "employee_id": "Номер співробітника",
                "person_id": "Ідентифікаційний номер (9 цифр)",
                "validate": "Перевірити код співробітника",
                "alreadyApplied": "Субсидію вже застосовано",
                "addOneMore": "Додати ще одну субсидію",
                "addOneMoreId": "Додати іншого співробітника",
                "fieldRequired": "Обов'язкове поле",
                "priceReducedBy": "Ціна знижена на",
                "forEmployee": "Для співробітника",
                "employeeIs": "Успішно ідентифікований",
                "error4401": "Не вдалося ідентифікувати, спробуйте ще раз",
                "error4402": "Код ідентифіковано, але вже використовується",
                "guestIdentifiedId": "Ідентифікований за ідентифікаційним номером",
                "guestNotIdentified": "Ви повинні ідентифікуватися перед реєстрацією",
                "clickToIdentify": "Клацніть, щоб ідентифікувати"
            },
            "uploadImage": {
                "uploadPassportImage": "Завантажити фото паспорта",
                "somethingWentWrong": "Щось пішло не так"
            }
        }
    }
};

export default translationsEn;