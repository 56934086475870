import { Button, Tooltip } from 'antd';
import React, { ReactNode, useCallback } from 'react';
import update from 'immutability-helper';
import { CgTrash } from 'react-icons/cg';
import { FaArrowDown, FaArrowUp, FaEdit, FaPlus } from 'react-icons/fa';
import './editComponent.less';
import { useSearchParams } from 'react-router-dom';

interface Props {
  children: ReactNode;
  instanceName: string;
  id?: string;
  index?: string;
  setComponents?: any;
  allowOnlyEdit?: boolean;
  setIsEditModeOn: React.Dispatch<React.SetStateAction<string | null>>;
  isEditModeOn: string | null;
}

const EditComponent = ({
  children,
  instanceName,
  id,
  index,
  setComponents,
  allowOnlyEdit = false,
  isEditModeOn,
}: Props) => {
  const [searchParams] = useSearchParams();
  const isEditMode = searchParams.get('editMode') === 'true';

  const handleEditOpen = (name: string) => {
    window.parent.postMessage({ message: name }, 'http://localhost:3000');
  };

  const moveModule = useCallback(
    (dragIndex: any, hoverIndex: any) => {
      setComponents((prevCards: any) => {
        return update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        });
      });
    },
    [setComponents]
  );

  const handleDelete = (name: string) => {
    setComponents((prevCards: any) =>
      prevCards.filter((item: any) => item.ComponentName !== name)
    );
  };

  const handleAddModule = (i: number) => {
    window.parent.postMessage({ addModule: i }, 'http://localhost:3000');
  };

  return (
    <div
      className={`${isEditMode ? 'EDIT' : ''} ${
        allowOnlyEdit ? 'ONLY-EDIT' : ''
      } ${isEditModeOn === instanceName ? 'EDIT-MODE-ON' : ''}`}
    >
      {isEditMode && (
        <div className="EDIT-BUTTON">
          {!allowOnlyEdit && (
            <Button
              className="add-btn-top"
              icon={<FaPlus />}
              onClick={() => handleAddModule(Number(index))}
            >
              Add Module on Top
            </Button>
          )}

          <div>
            {!allowOnlyEdit && (
              <div className="arrows-wrapper">
                <FaArrowUp
                  className="button"
                  size={18}
                  onClick={() => moveModule(Number(index) - 1, index)}
                />
                <FaArrowDown
                  className="button"
                  size={18}
                  onClick={() => moveModule(Number(index), Number(index) + 1)}
                />
              </div>
            )}

            <div
              onClick={() => {
                handleEditOpen(instanceName);
              }}
              className="edit"
            >
              <FaEdit size={18} className="button" />
            </div>
            {!allowOnlyEdit && (
              <div
                className="delete"
                onClick={() => {
                  handleDelete(instanceName);
                }}
              >
                <CgTrash size={18} className="button" />
              </div>
            )}
          </div>

          {!allowOnlyEdit && (
            <Button
              className="add-btn-bottom"
              icon={<FaPlus />}
              onClick={() => handleAddModule(Number(index) + 1)}
            >
              Add Module on Bottom
            </Button>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default EditComponent;
