import { createSlice } from '@reduxjs/toolkit';
import { PackageDataInterface, packageFormattedDataInterface } from './packageDataInterface';


const initialState:{
  packageData:PackageDataInterface
  packageFormattedData:packageFormattedDataInterface
}  = {
  packageData:{} as PackageDataInterface,
  packageFormattedData:{} as packageFormattedDataInterface
} ;

export const searchSlice = createSlice({
  name: 'packageData',
  initialState,
  reducers: {
    updatePackageData: (state, action) => {
      state.packageData = action.payload;
    },
    updateFormattedData: (state, action) => {
      state.packageFormattedData = action.payload;
    },
  },
});

export const { updatePackageData,updateFormattedData } = searchSlice.actions;

export default searchSlice.reducer;
