import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { ShoppingCart } from '../interfaces';
import FlightsSet1 from '../../sharedWidgets/FlightsNew/FlightsSet1/FlightsSet1';
import { PackageSettingsInterface } from '../../PackageDetails/packageDataInterface';
import { isEmpty } from 'lodash';
import { ShoppingCartFlightInterface } from '../ShoppingCartSlice';

interface Props {
  shoppingCartFlight: ShoppingCartFlightInterface;
  packageSettings: PackageSettingsInterface;
}

const ShoppingCartFlightsNew = ({
  shoppingCartFlight,
  packageSettings,
}: Props) => {
  const { shoppingCartTranslations: t } = useSelector(
    (data: RootState) => data.config.translations
  );

  return !isEmpty(shoppingCartFlight) ? (
    <div className="flights">
      <b>{t?.flights}</b>
      <FlightsSet1
        shoppingCartFlight={shoppingCartFlight}
        packageSettings={packageSettings}
      />
    </div>
  ) : null;
};

export default ShoppingCartFlightsNew;
