import React from 'react';
import {Alert, Spin} from 'antd';

import './crud-helper.less';
import {useSelector} from "react-redux";
import {TbError404, TbFaceIdError} from "react-icons/tb";

interface Props {
    apiStatusMessage: ApiStatusMessage,
    t: any;
}

export enum ApiStatus {
    success="success",
    error="error"
}

export interface ApiStatusMessage {
    status?: ApiStatus,
    data: any
}

const ModelApiMessage = ({ apiStatusMessage, t }: Props) => {

    if (apiStatusMessage.status === ApiStatus.error) {
        if (apiStatusMessage.data?.name === "AxiosError") {
            return <Alert
                message={t.operationError}
                description={apiStatusMessage.data.message}
                type="error"
                closable
            />;
        } else if (apiStatusMessage.data?.name === "CustomErrorMessage") {
            return <Alert
                message={t.operationError}
                description={apiStatusMessage.data.message}
                type="error"
                closable
            />;
        } else {
            // internal api error - validation fail
            if (apiStatusMessage.data?.code == 505) {
                return <Alert
                    message={t.validationFailed}
                    description={ apiStatusMessage.data.errors ?
                    <ul>{Object.keys(apiStatusMessage.data.errors).map((key, value) => {
                        return (<li key={key}>{apiStatusMessage.data.errors[key]}</li>);
                    })}</ul> : null}
                    type="warning"
                    closable
                />;
            } else {
                return <Alert
                    message={t?.message ? t.message : apiStatusMessage.data.message}
                    description={apiStatusMessage.data.errors ? <ul>{Object.keys(apiStatusMessage.data.errors).map((key, value) => {
                        return (<li key={key}>{apiStatusMessage.data.errors[key].toString()}</li>);
                    })}</ul> :null}
                    type="warning"
                    closable
                />;
            }
        }
    }

    if (apiStatusMessage.status === ApiStatus.success) {
        return <Alert
            message={apiStatusMessage.data.title}
            description={apiStatusMessage.data.message}
            type="success"
            closable
        />;
    }
    return null;
};

export default ModelApiMessage;
