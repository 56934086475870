import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import './shoppingCart.less';
import { useNavigate } from 'react-router-dom';
import { removeRoom, updateActivities } from './ShoppingCartSlice';
import ShoppingCartHeader from './widgets/ShoppingCartHeader';
import ShoppingCartEmpty from './widgets/ShoppingCartEmpty';
import ShoppingCartMainProduct from './widgets/ShoppingCartMainProduct';
import ShoppingCartRooms from './widgets/ShoppingCartRooms';
import ShoppingCartFlights from './widgets/ShoppingCartFlights';
import ShoppingCartTransfers from './widgets/ShoppingCartTransfers';
import ShoppingCartActivities from './widgets/ShoppingCartActivities';
import { isEmpty } from 'lodash';
import { AiFillCloseCircle } from 'react-icons/ai';
import DiscountCode from './widgets/DiscountCode';
import ShoppingCartSubsidize from './widgets/ShoppingCartSubsidize';
import { Tooltip } from 'antd';
import ShoppingCartPassengers from './widgets/ShoppingCartPassengers';
import ShoppingCartFlightsNew from './widgets/ShoppingCartFlightsNew';
import useHandleCheckout from './hooks/useHandleCheckout';

interface Props {
  pageType: string;
  open?: boolean;
  closeFromHeader?: () => void;
  showDiscounCodeOrIntegrationInfo?: boolean;
}

const ShoppingCart = ({
  pageType,
  open,
  closeFromHeader,
  showDiscounCodeOrIntegrationInfo = false,
}: Props) => {
  const isCheckoutPage = pageType === 'checkout' || pageType === 'manage-order';
  const dispatch = useDispatch();
  const [shoppingCartOpen, setShoppingCartOpen] = useState(false);
  const { shoppingCartTranslations: t } = useSelector(
    (data: RootState) => data.config.translations
  );

  const { shoppingCart, appliedSubsidize, flight:shoppingCartFlight } = useSelector(
    (state: RootState) => state.shoppingCart
  );

  useEffect(() => {
    if (!isEmpty(shoppingCart) && !isEmpty(shoppingCart.mainProductType)) {
      setShoppingCartOpen(true);
    }
  }, [shoppingCart]);

  const { settings } = useSelector((state: RootState) => state.shoppingCart);

  const handleDeleteActivity = (activity: string) => {
    const activities = { ...shoppingCart.activities };
    if (activities[activity].amount > 1) {
      activities[activity] = {
        ...activities[activity],
        amount: activities[activity].amount - 1,
      };
    } else {
      delete activities[activity];
    }
    dispatch(
      updateActivities({
        activities: activities,
      })
    );
  };

  const handleDeleteRoom = (index: number) => {
    dispatch(removeRoom({ roomIndex: index }));
  };

  const checkout = useHandleCheckout();
  const handleCheckout = () => {
    if (!isEmpty(shoppingCart)) checkout();
  }


  const showCloseButton = useMemo(
    () =>
      (open || shoppingCartOpen) &&
      pageType !== 'checkout' &&
      pageType !== 'manage-order',
    [open, pageType, shoppingCartOpen]
  );

  const freePackage = useMemo(() => {
    if (pageType === 'manage-order' && settings?.price_option_type === 'free') return true;
    if (settings?.price_option_type === 'free') return true;
    return false;
  }, [settings, pageType]);

  const className = settings?.flight_only ? "flight-only" : "";

  return (
    <div className={"shopping_cart " + className}>
      {showCloseButton && (
        <AiFillCloseCircle
          size={30}
          className="close"
          onClick={() => {
            closeFromHeader && closeFromHeader();
            setShoppingCartOpen(false);
          }}
          id="btn-close-cart"
        />
      )}
      <ShoppingCartHeader
        checkout={isCheckoutPage}
        setShoppingCartOpen={setShoppingCartOpen}
        shoppingCartOpen={shoppingCartOpen || Boolean(open)}
      />

      {(shoppingCartOpen || Boolean(open)) && (
        <>
          {isEmpty(shoppingCart) ? (
            <ShoppingCartEmpty />
          ) : (
            <>
            <div className="content">
              { !isEmpty(shoppingCartFlight)  && <>
                <ShoppingCartFlightsNew shoppingCartFlight={shoppingCartFlight} packageSettings={settings} />
                <ShoppingCartPassengers selectedSuitcase={shoppingCartFlight.selectedSuitcase} selectedTrolly={shoppingCartFlight.selectedTrolly} pax={shoppingCartFlight.flightInfo.pax} price={shoppingCartFlight.flightInfo.flightsInfo?.price} trollyPrice={shoppingCartFlight.trollyPrice} suitcasePrice={shoppingCartFlight.suitcasePrice}/>
              </>
              }
              <>
                {shoppingCart.rooms && (
                  <>
                    <ShoppingCartMainProduct shoppingCart={shoppingCart} />
                    {settings.show_flights_cart &&
                        <ShoppingCartFlights shoppingCart={shoppingCart} packageSettings={settings} />
                    }
                    <ShoppingCartRooms
                      shoppingCart={shoppingCart}
                      checkout={isCheckoutPage}
                      handleDeleteRoom={handleDeleteRoom}
                    />
                    <ShoppingCartTransfers shoppingCart={shoppingCart} />
                  </>
                )}
              </>
              <ShoppingCartActivities
                shoppingCart={shoppingCart}
                handleDeleteActivity={handleDeleteActivity}
                isCheckoutPage={isCheckoutPage}
              ></ShoppingCartActivities>

              {appliedSubsidize?.length > 0 && <ShoppingCartSubsidize />}
            </div>
            </>
          )}

          {settings?.show_coupon_code && showDiscounCodeOrIntegrationInfo && (
            <DiscountCode
              t={t}
              shoppingCart={shoppingCart}
              pageType={pageType}
            />
          )}
          {
            <div className="footer">
              <Tooltip title={isEmpty(shoppingCart) ? t.shoppingCartButtonTooltip : ""}>
                <div
                  className="btn-style-5"
                  onClick={!isCheckoutPage ? handleCheckout : undefined}
                >
                  {!freePackage ? (
                    <>
                      <div>
                        <span className="currency-sign">
                          {shoppingCart.currency_sign}
                        </span>{' '}
                        {t.totalPrice}
                      </div>
                      {shoppingCart.totalPrice}
                      {shoppingCart.currency_sign}
                    </>
                  ) : (
                    <div className="free-package">{t.placeOrder}</div>
                  )}
                </div>
              </Tooltip>
              {!isEmpty(shoppingCart) && !isCheckoutPage && !freePackage ? (
                <div
                  className="move-to-checkout"
                  onClick={!isCheckoutPage ? handleCheckout : undefined}
                >
                  {t.proceedToPayment}
                </div>
              ) : null}
            </div>
          }
        </>
      )}
    </div>
  );
};

export default ShoppingCart;
