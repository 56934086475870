import React from 'react';
import './flightsSet1.less';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { Flight } from '../../../ShoppingCart/interfaces';
import FlightBox from './Components/FlightBox';
import { PackageSettingsInterface } from '../../../PackageDetails/packageDataInterface';
import { ShoppingCartFlightInterface } from '../../../ShoppingCart/ShoppingCartSlice';

interface Props {
  shoppingCartFlight: ShoppingCartFlightInterface;
  flightComment?: any;
  packageSettings?: PackageSettingsInterface;
}

const FlightsSet1 = ({
  shoppingCartFlight,
  flightComment,
  packageSettings,
}: Props) => {
  const { packages: t } = useSelector(
    (data: RootState) => data.config.translations
  );

  return shoppingCartFlight ? (
    <div>
      {
        <div className="package-flights-new-set-1 content-wrapper">
          <h4>{t.flightDetails}</h4>
          {flightComment ? <h3>{flightComment}</h3> : null}
          <div>
            {packageSettings?.hide_flight_from ? (
              ''
            ) : (
              <>
                {shoppingCartFlight.flightInfo.flightsInfo.flightBack && (
                  <FlightBox
                    flight={
                      shoppingCartFlight.flightInfo.flightsInfo.flightBack
                    }
                    flightFrom={false}
                  />
                )}
              </>
            )}
            {packageSettings?.hide_flight_back ? (
              ''
            ) : (
              <>
                {shoppingCartFlight.flightInfo.flightsInfo.flightFrom && (
                  <FlightBox
                    flight={
                      shoppingCartFlight.flightInfo.flightsInfo.flightFrom
                    }
                    flightFrom={true}
                  />
                )}
              </>
            )}
          </div>
        </div>
      }
    </div>
  ) : null;
};

export default FlightsSet1;
