import apiClient from '@skygroup/shared/API/apiClient';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateAgentInfo } from '../store/config';
import endpoints from '../utils/endpoints';

const agentInfoApi = (token: string) => {
  return apiClient
    .post(`${endpoints.AGENT.POST_INFO}`, { token: token })
    .then((res) => {
      return res.data;
    });
};

export const useLoadAgentUser = () => {
  const dispatch = useDispatch();
  const [isAgentLoading, setIsAgentLoading] = useState(false);
  const [agentInfo, setAgentInfo] = useState<any>({});

  const getAgentInfo = useCallback(async () => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const token =
        searchParams.get('t') || localStorage.getItem('agent-admin-token');

      if (!token) {
        return;
      }

      setIsAgentLoading(true);
      const userInfoRes: any = await agentInfoApi(token);

      if (userInfoRes.status === 'success') {
        dispatch(updateAgentInfo(userInfoRes.data));
        setAgentInfo(userInfoRes.data);
        localStorage.setItem('agent-admin-token', token);
      } else {
        dispatch(updateAgentInfo({}));
        setAgentInfo({});
        localStorage.removeItem('agent-admin-token');
      }
      setIsAgentLoading(false);
    } catch (error: any) {
      setIsAgentLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    getAgentInfo();
  }, [getAgentInfo]);

  return { isAgentLoading, agentInfo };
};
