import React from 'react';
import './flightsSet2.less';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { Flight } from '../../../ShoppingCart/interfaces';
import FlightBox from './Components/FlightBox';
import { PackageSettingsInterface } from '../../../PackageDetails/packageDataInterface';
import { Col, Row } from 'antd';

interface Props {
  flights?: Flight[] | undefined;
  flightComment?: any;
  packageSettings?: PackageSettingsInterface;
}

const FlightsSet1 = ({ flights, flightComment, packageSettings }: Props) => {
  const { packages: t } = useSelector(
    (data: RootState) => data.config.translations
  );

  return flights ? (
    <div>
      {flights.length > 0 && (
        <div className="package-flights-set-2 content-wrapper">
          <h4 className="header-section">{t.flightDetails}</h4>
          {flightComment ? <h3>{flightComment}</h3> : null}
          <Row>
            {packageSettings?.hide_flight_from ? (
              ''
            ) : (
              <Col md={12} sm={24}>
                <FlightBox flight={flights[0]} flightFrom={true} />
              </Col>
            )}
            {packageSettings?.hide_flight_back ? (
              ''
            ) : (
              <Col md={12} sm={24}>
                <FlightBox flight={flights[1]} flightFrom={false} />
              </Col>
            )}
          </Row>
        </div>
      )}
    </div>
  ) : null;
};

export default FlightsSet1;
